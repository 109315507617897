import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Card,
  Tabs,
  FormLayout,
  TextField,
  DisplayText,
  EmptyState,
  Badge,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import "../scss/importproduct.scss";
import SkeletonViewImportProduct from "./skeletons/SkeletonViewImportProduct";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import {
  ASSET_PATH,
} from "../../appconfig/Settings";
import "react-quill/dist/quill.snow.css";
import { GET_PRODUCT } from "../../apis/ApiConstant";
import axiosInstance from "../../services/axios.service";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../scss/helicore_common.scss";

export default function ViewProduct(props) {
  document.title = "View Product";
  const [pageLoad, setPageLoad] = useState(false);
  const [importProduct, setImportProduct] = useState();
  const [selected, setSelected] = useState(0);
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  const tabs = [
    {
      id: 0,
      content: 'Product',
    },
    {
      id: 1,
      content: 'About the Book',
    },
    {
      id: 2,
      content: 'About the Author',
    },
    {
      id: 3,
      content: 'Variants',
    },
    {
      id: 4,
      content: 'Paper Back'
    },
    {
      id: 5,
      content: 'Images',
    },
  ];

  useEffect(() => {
    getDataFromAPI()
  }, []);

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const getDataFromAPI = () => {
    const product_id = props.location.state;
    axiosInstance.get(GET_PRODUCT + "/" + product_id).then((res) => {
      const productList = res.data.data;

      //add collections to object
      const collection_data = res.data;
      if (productList.product_collection != null && productList.product_collection != undefined) {
        const collections = [];
        const selectedCollectionArray = productList.product_collection.split(',');
        for (let i = 0; i < collection_data.length; i++) {
          const collectionObj = {};
          collectionObj["label"] = collection_data[i].category_name;
          collectionObj["value"] = collection_data[i].shopify_collection_id.toString();
          collections.push(collectionObj);
        }
        productList["selected_collection"] = selectedCollectionArray;
        productList["collectionObj"] = collections;
        productList["popover"] = false;
      } else {
        const collections = [];
        for (let i = 0; i < collection_data.length; i++) {
          const collectionObj = {};
          collectionObj["label"] = collection_data[i].category_name;
          collectionObj["value"] = collection_data[i].shopify_collection_id.toString();
          collections.push(collectionObj);
        }
        productList["collectionObj"] = collections;
        productList["popover"] = false;
        productList["selected_collection"] = [];
      }

      setImportProduct((prev) => productList);
      setPageLoad(true);
    });
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row import-product import-wrapper justify-content-center">
          <Page title="View Product"
            breadcrumbs={[
              {
                content: "Reports",
                onAction: handleBack,
              },
            ]}
          >
            {pageLoad ? (
              <div>
                <div className="main-wrapper">
                  <>
                    <Card>
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 width-50">
                          </div>
                        </div>
                      </div>

                      <Tabs tabs={tabs} selected={selected} onSelect={(selected) => handleTabChange(selected)}>

                        {selected == 0 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                                      <div className="product-img">
                                        {importProduct.featured.image != "" || importProduct.featured.image != null ? (
                                          <div className="product-img-wrapper">
                                            <img
                                              key={0}
                                              src={
                                                importProduct.featured.image
                                              }
                                              alt="No Image"
                                            />
                                          </div>
                                        ) : (
                                          <div className="product-img-wrapper">
                                            <img
                                              src={
                                                NO_IMAGE
                                              }
                                            ></img>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                                      <div className="product-wrapper">
                                        <FormLayout>
                                          <DisplayText
                                            element="p"
                                            size="small"
                                          >
                                            {importProduct.title}
                                          </DisplayText>
                                          <TextField label="Title" value={importProduct.product_title} disabled />

                                          <TextField label="Type" value={importProduct.product_type} disabled />

                                          <TextField label="Original Publication Date" value={importProduct.publishing_date} disabled />

                                          <label><strong>Category</strong></label>
                                          <div style={{ display: 'flex' }}>
                                            {
                                              importProduct.category.map((i) => (
                                                <p>{i + ","}</p>
                                              ))
                                            }
                                          </div>

                                          <div>
                                            <span style={{ "fontSize": "0.875rem", "fontWeight": "600" }}>Status:</span>&nbsp;&nbsp;&nbsp;
                                            {importProduct.status == 1 ? <Badge status="success">Approved</Badge> : null}
                                            {importProduct.status == 0 ? <Badge progress="incomplete" status="warning">In-Review</Badge> : null}
                                          </div>
                                        </FormLayout>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }
                        {selected == 1 &&

                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="description-editor">
                                        <ReactQuill
                                          theme="snow"
                                          value={importProduct.about_book || ""}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 2 &&

                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="description-editor">
                                        <ReactQuill
                                          theme="snow"
                                          value={importProduct.about_author || ""}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 3 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="">
                                <div className="Polaris-DataTable variants-table">
                                  <div className="Polaris-DataTable__ScrollContainer">
                                    <table className="Polaris-DataTable__Table">
                                      <thead>
                                        <tr>
                                          {/* <th className="Polaris-DataTable__Cell"></th> */}
                                          <th className="Polaris-DataTable__Cell">
                                            Title
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            SKU
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Price
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Compare at Price
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Book Url
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {importProduct.variants.length > 0 ? importProduct.variants.map((importProductVariant, idx) => (
                                          <tr
                                            className="Polaris-DataTable__TableRow"
                                            key={idx}
                                          >
                                            {/* <th className="Polaris-DataTable__Cell">
                                              {importProductVariant.varient_url !== null &&
                                                importProductVariant.varient_url !== undefined ? (
                                                <img
                                                  onError={(e) => {
                                                    e.target.src = NO_IMAGE;
                                                  }}
                                                  src={importProductVariant.varient_url}
                                                  alt="No Image"
                                                />
                                              ) : (
                                                <img
                                                  src={NO_IMAGE}
                                                  alt="No Image2"
                                                />
                                              )}
                                            </th> */}
                                            <td className="Polaris-DataTable__Cell">
                                              {importProductVariant.varient_title}
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  disabled
                                                  value={importProductVariant.sku}
                                                  name="sku"
                                                />
                                              </div>
                                            </td>

                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="number"
                                                  label=""
                                                  prefix="$"
                                                  value={importProductVariant.price}
                                                  name="price"
                                                  disabled
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="number"
                                                  label=""
                                                  prefix="$"
                                                  value={
                                                    importProductVariant.compared_price
                                                  }
                                                  name="compared_at_price"
                                                  autoComplete={false}
                                                  disabled
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  value={
                                                    importProductVariant.book_url
                                                  }
                                                  name="book_url"
                                                  autoComplete={false}
                                                  disabled
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )) : <tr> <td colspan={9} style={{ "textAlign": "center" }}> <EmptyState heading="No Variants Avavilable"></EmptyState> </td> </tr>}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 4 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="">
                                <div className="Polaris-DataTable variants-table">
                                  <div className="Polaris-DataTable__ScrollContainer">
                                    <table className="Polaris-DataTable__Table">
                                      <thead>
                                        <tr>
                                          <th className="Polaris-DataTable__Cell">
                                            Title
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Link
                                          </th>

                                        </tr>
                                      </thead>
                                      <tbody>

                                        {importProduct?.paper_book?.length > 0 ? JSON.parse(importProduct?.paper_book).map((importProductPaperBuck, idx) => (
                                          <tr
                                            className="Polaris-DataTable__TableRow"
                                            key={idx}
                                          >
                                            <td className="Polaris-DataTable__Cell">
                                              <div
                                                style={{
                                                  maxWidth: "200px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductPaperBuck.textFieldValue}
                                                  name="textFieldValue"
                                                  placeholder="Title"
                                                  disabled
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "initial",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductPaperBuck.urlValue}
                                                  name="urlValue"
                                                  placeholder="UrlValue"
                                                  disabled
                                                />
                                              </div>
                                            </td>

                                          </tr>
                                        )) : <tr> <td colspan={9} style={{ "textAlign": "center" }}> <EmptyState heading="No Variants Avavilable"></EmptyState> </td> </tr>}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 5 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                                      <div className="featured_images">
                                        {importProduct.featured ? (
                                          <div className="featured_wrapper">
                                            <img
                                              src={importProduct.featured.image}
                                              alt="No Image"
                                            ></img>
                                          </div>
                                        ) : <div className="featured_wrapper">
                                          <img
                                            src={importProduct.images[0].image}
                                            alt="No Image"
                                          ></img>
                                        </div>
                                        }
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                                      <div className="row product-wrapper">
                                        {(importProduct.images && importProduct.images.length > 0) && importProduct.images.map(
                                          (importProduct) =>
                                            <div className="col-lg-3 col-md-4 col-6  col-xl-2 thumb-image">
                                              <div className="thumb-wrapper">
                                                <img
                                                  src={importProduct.image}
                                                  onError={(e) => {
                                                    e.target.src =
                                                      NO_IMAGE;
                                                  }}
                                                  alt="No Image"
                                                ></img>
                                                <center style={{ margin: "5px 0px" }}>
                                                  {importProduct.alt_txt.split('_').length >= 2 ?
                                                    <Badge size="small">{importProduct.alt_txt.split('_')[1]}</Badge>
                                                    : null}
                                                </center>
                                              </div>
                                            </div>
                                        )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }
                      </Tabs>
                    </Card>
                  </>
                </div>
              </div>
            ) : (
              <SkeletonViewImportProduct />
            )}
          </Page>
        </div>
      </div>
    </>
  );
}
