import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminAppContainer from "../Admin/layouts/AdminAppContainer"
import CustomerList from "../Admin/pages/CustomerList";
import Dashboard from "../Admin/pages/Dashboard";
import ManageCategories from "../Admin/pages/ManageCategories";
import ManageOrder from "../Admin/pages/ManageOrder";
import ProductList from "../Admin/pages/ProductList";
import ImportProduct from "../Admin/pages/ImportProduct";
import ViewImportProduct from "../Admin/pages/ViewImportProduct";
import ViewOrder from "../Admin/pages/ViewOrder";
import AddProduct from "../Admin/pages/AddProduct";
import ViewProduct from "../Admin/pages/ViewProduct";
import SubcriberList from "../Admin/pages/SubcriberList";
import NotFound from "../Admin/pages/NotFound";
import ChangePassword from "../Admin/pages/ChangePassword";

export default () => {
    return (
        <AdminAppContainer>
            <Switch>
                <Route
                    exact
                    component={Dashboard}
                    path="/admin/dashboard"
                />
                <Route
                    exact
                    component={ManageOrder}
                    path="/admin/manageOrders"
                />
                <Route
                    exact
                    component={ViewOrder}
                    path="/admin/view/manageOrders"
                />
                <Route
                    exact
                    component={ManageCategories}
                    path="/admin/manageCategory"
                />
                <Route
                    exact
                    component={ProductList}
                    path="/admin/products"
                />
                <Route
                    exact
                    component={ViewProduct}
                    path="/admin/view/product"
                />
                <Route
                    exact
                    component={AddProduct}
                    path="/admin/addProduct"
                />
                <Route
                    exact
                    component={ImportProduct}
                    path="/admin/importproduct"
                />
                <Route
                    exact
                    component={ViewImportProduct}
                    path="/admin/view/importproduct"
                />
                <Route
                    exact
                    component={CustomerList}
                    path="/admin/customers"
                />
                <Route
                    exact
                    component={SubcriberList}
                    path="/admin/subscriber"
                />
                <Route
                    exact
                    component={ChangePassword}
                    path="/admin/changepassword"
                />
                <Route component={NotFound} path="/*" />
            </Switch>
        </AdminAppContainer>
    )
}
