import React, { Component } from "react";
import { Card, SkeletonBodyText, Tabs, FormLayout } from "@shopify/polaris";
import { ASSET_PATH } from "../../../appconfig/Settings";

export default function SkeletonImportProducts() {
  const state = {
    selected: 0,
  };
  const tabs = [
    {
      id: "product",
      content: "Product",
      panelID: "product",
    },
    {
      id: "aboutTheBook",
      content: "About The Book",
      panelID: "aboutTheBook",
    },
    {
      id: "aboutTheAuthor",
      content: "About The Author",
      panelID: "aboutTheAuthor",
    },
    {
      id: "variants",
      content: "Variants",
      panelID: "variants",
    },
    {
      id: "peperBuck",
      content: "Peper Buck",
      panelID: "peperBuck",
    },
    {
      id: "images",
      content: "Images",
      panelID: "images",
    },
  ];

  return (
    <>
      <Card>
        <Tabs tabs={tabs} selected={state.selected}>
          {state.selected === 0 ? (
            <Card.Section>
              <Card.Section>
                <div className="middle-section">
                  <div className="main-wrapper">
                    <div className="row">
                      <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <div className="product-img">
                          <div className="product-img-wrapper">
                            <img
                              src={ASSET_PATH + "no-image.jpg?v=1654230497"}
                              alt="No Image"
                            ></img>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                        <div className="product-wrapper">
                          <FormLayout>
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                          </FormLayout>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Section>
            </Card.Section>
          ) : null}
        </Tabs>
        <div className="card-footer">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="bottom-section">
                <SkeletonBodyText />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
