import axios from "axios";
import { APP_BASE_URL } from "../appconfig/Settings";
import {BehaviorSubject, Subscriber, Observable, Subject} from 'rxjs';
import DataService from "./dataService";

let search = window.location.search;
let params = new URLSearchParams(search);

export default class AxiosService extends Subscriber {
  subject = new BehaviorSubject(null);
  controller = new AbortController();
  cancelToken = axios.CancelToken;
  #token;
  _dataService = new DataService();
  instance;

  tokenTracker = new Subject();


  constructor(observer) {
    super(observer)
  }

  // Callback(status, object)
  login(url, data, callback){
    axios.post(url,data).then((response) => {
      this.#token = response.data.token; // TODO: Set actual responce token
      this.createInstance();
      callback(response)
    }).catch(error => {
      callback(error)
    })
  }

  logout() {
    this.#token = null;
    // TODO: Redirect to login page
      this._dataService.clearAll();
      window.location.href = "/";
  }

  createInstance() {
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.instance.defaults.baseURL= `${APP_BASE_URL}/api/merchant`;
    this.instance.defaults.headers['Authorization'] = this.#token;
    this.instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance.interceptors.request.use(this.handleRequestSuccess, this.handleRequestError);
  }

  handleSuccess(response) {
    if (response) {
      if (response.data && response.data.token) {
        // append your request headers with an authenticated token
        response.headers["Authorization"] = `Bearer ${response.data.token}`;
      }
    }
    if (localStorage.hasOwnProperty("token")) {
      const token = localStorage.getItem("token");
      // append your request headers with an authenticated token
      response.headers["Authorization"] = `Bearer ${token}`;
    }
    return response;
  }

  handleError(error) {
    if (typeof error.response != 'undefined' ) {
      this._dataService.clearAll();
      this.logout();
    }
    switch (error.response.status){
      case 401:
        this._dataService.clearAll()
         this.logout();
        console.log("401 Error has thrown",error);
        break;
      case 404:
        console.log("404 Error has thrown", error);
        break;
      default:
        console.log("Something went wrong", error);
        break;
    }
    return Promise.reject(error);
  }

  handleRequestSuccess(request) {
    return request
  }

  handleRequestError(error) {
    console.log("Login Error")
    // Redirect to login Page
    window.location.href = "/";
  }

  get(path, callback) {
    return this.instance.get(path, {
      cancelToken: new this.cancelToken((c) => {
        this.subject.next(c)
      })
    }).then((responce) => {
      callback(responce.status, responce.data)
    })
  }

  post(path, payload, callback) {
    return this.instance.post({
      method: 'POST',
      url: path,
      data: payload
    }).then((responce) => {
      callback(responce.status, responce.data)
    })
  }

  unsubscribe() {
    super.unsubscribe();
  }

}
