import React, {useCallback, useEffect, useState} from 'react';
import {Banner, List, DropZone, Stack, Thumbnail, Button, Icon, Modal, TextField} from '@shopify/polaris';
import {DeleteMinor, NoteMinor} from '@shopify/polaris-icons';

export default function AddImage({  getImagesFromAddImage }) {
    const validImageTypes = ['image/jpeg', 'image/png'];
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [selIndex, setSelIndex] = useState('');
    const [altModal, setAltModal] = useState(false);
    const [altText, setAltText] = useState('');
    const [altTextValue, setAltTextValue] =  useState([]); 

    useEffect(() => {
        getImagesFromAddImage({
            getImages: sendImagesToParent,
            getAltText: sendAltTextToParent
        })
    })

    const sendImagesToParent = () => {
        return files
    }

    const sendAltTextToParent = () => {
        return altTextValue
    }

    const handleDropZone = useCallback(
        (_dropFiles, _acceptedFiles, _rejectedFiles) => {
            //Set Alttext array
            if(_rejectedFiles.length > 0){
                setRejectedFiles((files) => [...files, _rejectedFiles]);
            }
            if(_acceptedFiles.length > 0){
                setRejectedFiles([]);
                setFiles((files) => [...files, ..._acceptedFiles])

                //Alttext array set
                let altTextArray = [];
                for(var i=0;i<_acceptedFiles.length;i++){
                    let altTextObj = {};
                    altTextObj.altText = "";
                    altTextArray.push(altTextObj);
                }

                setAltTextValue([...altTextValue, ...altTextArray])
            }
        }
    );

    const removeClick = (index) => { 
        const allFiles = files;
        const tempArr = altTextValue;
        tempArr.splice(index,1);
        setAltTextValue(tempArr);
        allFiles.splice(index, 1);
        setFiles([...allFiles]);
    };

    //handle change alt text
    const handleAltText = (value) => {
        setAltText(value);
    };

    //add alt text
    const addAltImage = () => {
        let tempArray = altTextValue;
        tempArray[selIndex]["altText"] = altText;
        setAltTextValue(tempArray);
        setAltModal(false);
    };

    const uploadedFiles = files.length > 0 && (
        <div style={{margin: 10}}>
            <Stack alignment={"center"} distribution={"center"} spacing={"loose"}>
                {files.map((file, index) => (
                    <Stack alignment="center" spacing={"tight"} key={index}>
                        <div className="show-image">
                            <div className="show-image variant-img">
                                <div className="variant-img-select">
                                    
                                        <Thumbnail
                                            size="large"
                                            alt={file.name}
                                            source={
                                                validImageTypes.includes(file.type)
                                                    ? window.URL.createObjectURL(file)
                                                    : NoteMinor
                                            }
                                        />
                                
                                </div>
                            </div>
                            <div className="deleteBtn">
                            <div className="delete">
                                <Button size={"slim"} onClick={() => removeClick(index)}><Icon source={DeleteMinor} /></Button>
                            </div>
                            </div>
                        </div> 
                    </Stack>
                ))}
            </Stack>
        </div>
    );

    const hasError = rejectedFiles.length > 0;

    const errorMessage = hasError && <Banner title="The following images couldn\u2019t be uploaded:" status="critical">
        <List type="bullet">
            {rejectedFiles.map((file, index) => <List.Item key={index}>
                {`"${file[index].name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
            </List.Item>)}
        </List>
    </Banner>;

    return (
        <>
            {errorMessage}
            <br/>
            <div className="image_add_box">
                <DropZone accept="image/*" type="image" onDrop={handleDropZone} style={{zIndex: 1}}>
                    {/*{!files.length && <DropZone.FileUpload />}*/}
                    <DropZone.FileUpload />
                </DropZone>
            </div>

            {/* Add Alt Modal */}
            <Modal
                open={altModal}
                onClose={() => setAltModal(false)}
                title="Add alt text to image"
                primaryAction ={[
                {
                    content: 'Submit',
                    onAction: () => addAltImage(),
                },
                ]}
                secondaryActions={{
                content: 'Cancel',
                onAction: () => setAltModal(false),
                }}
            >
                <Modal.Section>
                    <TextField
                      autoComplete="off"
                      placeholder="Enter Alt Text"
                      value={altText || ""}
                      onChange={(value) => handleAltText(value)}
                    />
                </Modal.Section>
            </Modal>

            {uploadedFiles}
        </>
    )
}


