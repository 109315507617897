import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  DataTable,
  Icon,
  TextField,
  Page,
  Pagination,
  Stack,
  Select,
  Spinner,
  Modal,
  FormLayout,
  TextStyle,
  Link,
} from "@shopify/polaris";
import { SearchMinor, ViewMinor } from "@shopify/polaris-icons";
import { defaultPageOptions, ASSET_PATH, SEARCH_IMG, PRODUCT_VIEW, showing } from "../../appconfig/Settings";
import SkeletonProductDatatable from "./skeletons/SkeletonProductDatatable";
import "../scss/myproducts.scss";
import axiosInstance from "../../services/axios.service";
import { LIST_SUBSCRIBER } from "../../apis/ApiConstant";
import { formatDateTime, setPagination } from "../../appconfig/AppHelper";
import { useHistory } from "react-router-dom";

export default function SubscriberList() {
  document.title = "Subscriber List";
  const [pageLoad, setPageLoad] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [sort, setSort] = useState("updatenew");
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [subscriberTitle, setSubscriberTitle] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  useEffect(() => {
    getDataFromAPI();
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleSubscriberTitleChange = (value) => {
    setSubscriberTitle(value);
  };

  const handleClearSubscriberTitle = () => {
    setSubscriberTitle("");
  };

  const handleSearchButton = () => {
    setLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setLoading(false);
  };

  const getDataFromAPI = () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    axiosInstance.get(LIST_SUBSCRIBER + "?per_page=" + perPage + "&page=" + page + "&search=" + subscriberTitle).then((res) => {
      const subscriberList = res.data.data.data;
      if (subscriberList.length == 0) {
        setTableRows([]);
        setPaginationActive(false);
      } else {
        let tableData = [];

        for (let i = 0; i < subscriberList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(i+1);
          tableRowsAppend.push(subscriberList[i]["email"]);
          tableRowsAppend.push(formatDateTime(subscriberList[i]["created_at"]));

          //push in empty array
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);
      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = res.data;
      const pagination = setPagination(current_page, per_page, last_page, total);
      setPageinationInner(pagination);
    });
  }

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    )
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row myproducts-list justify-content-center">
          <Page title="Subscriber List">
            <Card>
              <Card.Section subdued>
                <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                  <div className="col-lg-8 col-md-4 col-sm-12 col-xs-12">
                    <TextField
                      labelHidden
                      autoComplete="off"
                      placeholder="Enter Product Title"
                      value={subscriberTitle || ""}
                      onChange={(value) => handleSubscriberTitleChange(value)}
                      prefix={<Icon source={SearchMinor} color="inkLighter" />}
                      clearButton
                      onClearButtonClick={() => handleClearSubscriberTitle()}
                    />
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 form-group">
                    <Button
                      loading={loading}
                      onClick={() => handleSearchButton()}
                      primary
                      fullWidth
                    >
                      SEARCH
                    </Button>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Select
                      label="Show"
                      labelInline
                      onChange={handleChangePerpage}
                      value={perPage}
                      options={defaultPageOptions}
                    ></Select>
                  </div>
                </div>
              </Card.Section>

              <div className="data-wrapper">
                {pageLoad ? (
                  <div>
                    <DataTable
                      columnContentTypes={
                        [
                          "text",
                          "text",
                          "text",
                        ]}
                      headings={
                        ["Sr No.",
                          "Email",
                          "Created At",
                        ]}
                      rows={tableRows}
                      footerContent={
                        tableRows.length > 0 ? (
                          <span>{paginationInner.showing}</span>
                        ) : null
                      }
                    />
                    <div className="paginate">
                      <Stack distribution="center">
                        {paginationActive ? (
                          <Paginate />
                        ) : null}
                      </Stack>
                    </div>
                    {tableRows.length == 0 ? (

                      <div className="Polaris-ResourceList__EmptySearchResultWrapper">
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
                          <Stack.Item>
                            <img
                              src={SEARCH_IMG}
                              alt="search_img"
                              className="Polaris-EmptySearchResult__Image"
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                              No Products found
                            </p>
                          </Stack.Item>
                        </div>
                      </div>

                    ) : null}
                  </div>

                ) : (
                  <SkeletonProductDatatable />
                )}
                {dataTableInnerLoading ? (
                  <div className="loading">
                    <Spinner size="large" />
                  </div>
                ) : null}
              </div>
            </Card>

          </Page>
        </div>
      </div>
    </>
  );
}
