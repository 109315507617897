import React, {useState, useContext} from "react";
import {
    TextField,
    Layout,
    Button,
    Card,
    Page,
    FormLayout,
    Link,
    TextStyle,
} from "@shopify/polaris";
import {LOGIN} from "../apis/ApiConstant";
import {Form} from "react-bootstrap";
import { Buffer } from "buffer";
import { emailPattern } from "../appconfig/Settings";
import ToasterToggle from "../Admin/components/Toster";
import { AuthContext } from "../services/AuthProvider";

export default function Login() {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isError, setErrors] = useState(false);
    const auth = useContext(AuthContext);
    const [login, setLogin] = useState({});
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [iserror, setisError] = useState(false);
    const _onHandleChange = (name, value) => {
        setLogin({...login, [name]: value});
    };

    //Validation registration data
    const validate = () => {
        let isValidate = true;
        let error = {};
        if (window.cn(login.email)
        && !window.cb(login.email)) {
            error = { ...error, email: "Enter Email*" };
            isValidate = false;
        }
        else if(!emailPattern.test(login.email)){
            error = { ...error, email: "Enter Valid Email*" };
            isValidate = false;
        }
        else {
            error = { ...error, email: "" };
        }
        if(window.cn(login.password)){
          isValidate = false;
          error["password"] = "Enter Password*";
        }
        setErrors(error);
        return isValidate; 
    }

    const _submitForm = async () => {
        if(validate()){
            setButtonLoading(true);
            const finalObj = {};
            const email = login.email.trim();
            //Password base64 convert
            let passwordBuff = Buffer.from(login.password).toString('base64');
            finalObj.email = email;
            finalObj.password = passwordBuff;
            
            //Auth Sign Call
            auth.signin(LOGIN, finalObj).then((res) => {
                setButtonLoading(false);
            }).catch(error => {
                setButtonLoading(false);
                setToastToggle(true);
                setToastMessage(error.response.data.error);
                setisError(true);
            })
        }
    }
    const logoImg = "https://cdn.shopify.com/s/files/1/0649/0439/7023/files/PublixPress-Logo.png?v=1654144133";

    return (
        <Page>
            <div style={{"transform": "translate(0%,40%)"}}>
            <Layout>
                <Layout.AnnotatedSection
                    title={<h4>Welcome To Publix Press Admin Account</h4>}
                    description={<><br/><p>Please sign in to add product on Publix Press<br/><center>&</center>Get access to your full-fledged seller dashboard</p></>}
                >
                    <Layout.Section oneHalf>
                        <Card sectioned>
                            <div style={{"textAlign": "center"}}>
                                <img src={logoImg} />
                            </div>
                            <br/>
                            <Form onSubmit={_submitForm}>
                                <FormLayout>
                                    <TextField
                                        autoComplete="off"
                                        label="Email"
                                        placeholder="Please enter your email"
                                        value={login.email || ""}
                                        onChange={(value) => _onHandleChange("email", value)}
                                        error={isError.email}
                                    />
                                    <TextField
                                        autoComplete="off"
                                        label="Password"
                                        type="password"
                                        placeholder="Please enter your password"
                                        value={login.password || ""}
                                        onChange={(value) => _onHandleChange("password", value)}
                                        error={isError.password}
                                    />
                                    <Button primary loading={buttonLoading} onClick={_submitForm}>
                                        Submit
                                    </Button>
                                    {/* <TextStyle variation="subdued">Not yet registerd? <Link url="/registration">Click here!</Link></TextStyle>
                                    <TextStyle variation="subdued">Forgot Password? <Link url="/forgot-password">Click here!</Link></TextStyle> */}
                                </FormLayout>
                            </Form>
                        </Card>
                    </Layout.Section>
                </Layout.AnnotatedSection>
            </Layout>
            </div>
            {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={iserror} />}
        </Page>
    )
}
