import React, { useCallback, useEffect, useState } from 'react';
import { DropZone, Stack } from '@shopify/polaris';

export default function UploadFile({ getFilesFromFiles }) {

    useEffect(() => {
        getFilesFromFiles({
            getFiles: sendFilesToParent
        })
    })

    const sendFilesToParent = () => {
        return files
    }

    let [files, setFiles] = useState([]);

    const handleDropZone = useCallback(
        (_dropFiles) => {
            if (_dropFiles.length > 0) {
                setFiles((file) => [..._dropFiles])
                files = _dropFiles;
            }

        }
    )

    const uploadedFiles = files.length > 0 && (
        <div style={{ margin: 10 }}>
            <Stack alignment={"center"} distribution={"center"} spacing={"loose"}>
                {files.map((file, index) => (
                    <Stack alignment="center" spacing={"tight"} key={index}>
                        {/* <div className="show-image"> */}
                        {/* <Thumbnail
                                size="small"
                                source={window.URL.createObjectURL(file)
                                }
                            /> */}
                        {/* <img src={file} /> */}
                        <div>{file.name}</div>
                        {/* </div> */}
                    </Stack>
                ))}
            </Stack>
        </div>
    );

    return (
        <>
            <div className="image_add_box">
                <div style={{ width: 50, height: 50 }}>
                    <DropZone type="file" onDrop={handleDropZone} allowMultiple={false}>
                        <DropZone.FileUpload />
                    </DropZone>
                </div>
            </div>
            {uploadedFiles}
        </>
    )
}


