import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  DataTable,
  Icon,
  TextField,
  Page,
  Pagination,
  Stack,
  Select,
  Spinner,
  Modal,
  FormLayout,
  TextStyle,
  Link,
} from "@shopify/polaris";
import { SearchMinor, ViewMinor } from "@shopify/polaris-icons";
import { sortOptions, defaultPageOptions, ASSET_PATH, SEARCH_IMG, PRODUCT_VIEW, PRODUCT_EDIT, showing } from "../../appconfig/Settings";
import SkeletonProductDatatable from "./skeletons/SkeletonProductDatatable";
import "../scss/myproducts.scss";
import axiosInstance from "../../services/axios.service";
import { DELETE_PRODUCT_BY_SHOPIFY_ID, MY_PRODUCT } from "../../apis/ApiConstant";
import { setPagination } from "../../appconfig/AppHelper";
import ToasterToggle from "../components/Toster";
import { useHistory } from "react-router-dom";

export default function ProductList() {
  document.title = "Products";
  const [pageLoad, setPageLoad] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [sort, setSort] = useState("updatenew");
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [productTitle, setProductTitle] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteDeactiveModal, setDeleteDeactiveModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  useEffect(() => {
    getDataFromAPI();
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, sort]);

  //approve modal
  const approveModalAddActive = (id) => {
    setApproveModal(true);
    setSelectedId(id);
  };

  //Delete Deactive Modal
  const deleteDeactiveModalAddActive = (id) => {
    setDeleteDeactiveModal(true);
    setSelectedId(id);
  };

  const history = useHistory();
  const handleView = (product_id) => {
    let path = `/admin/view/product`;
    history.push({ pathname: path, state: product_id });
  };

  const handleChangeSort = (value) => {
    paginationInner.page = 1;
    setSort(value);
  };

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleProductTitleChange = (value) => {
    setProductTitle(value);
  };

  const handleClearProductTitle = () => {
    setProductTitle("");
  };

  const handleSearchButton = () => {
    setLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setLoading(false);
  };

  //Approve withdraw request
  const handleWithdrawReq = () => {
    setButtonLoading(true);
    //formdata object
    const formData = new FormData();
    formData.append("shopify_id", selectedId);
  };

  const handleDeleteDeactiveProduct = () => {
    setButtonLoading(true);
    //formdata object
    axiosInstance.post(DELETE_PRODUCT_BY_SHOPIFY_ID + "/" + selectedId).then((res) => {
      setButtonLoading(false);
      setToastToggle(true);
      setToastMessage(res.data.sucess);
      getDataFromAPI();
      setDeleteDeactiveModal(false);
    }).catch((error) => {
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
      setDeleteDeactiveModal(false);
    });
  };

  const getDataFromAPI = () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    axiosInstance.get(MY_PRODUCT + "?per_page=" + perPage + "&page=" + page + "&title=" + productTitle + "&sort=" + sort).then((res) => {
      const productList = res.data.data;
      if (productList.length == 0) {
        setTableRows([]);
        setPaginationActive(false);
      } else {
        let tableData = [];

        for (let i = 0; i < productList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(<div className="img-list-wrapper"><img src={productList[i].featured ? productList[i].featured.image : NO_IMAGE} alt="No Image"></img></div>);
          tableRowsAppend.push(<div className="side-icon"><a className="primary_link" href={PRODUCT_VIEW + productList[i].shopify_product_handle} removeunderline="true" monochrome="false" target="_blank">{productList[i].product_title}<Icon source={ViewMinor} color="black" /></a></div>);
          tableRowsAppend.push(<div className="side-icon">{productList[i].author_name}</div>);
          tableRowsAppend.push(<div className="side-icon">{productList[i].publishing_date}</div>);

          if (productList[i].variants && productList[i].variants.length > 0) {
            let total_price = 0;
            productList[i].variants.map((item) => {
              if (item.price != "" && item.price != null && item.price != undefined) {
                total_price += parseFloat(item.price);
              }
            });
            tableRowsAppend.push("$"+total_price.toFixed(2));
          } else {
            tableRowsAppend.push("-");
          }
          tableRowsAppend.push(productList[i].product_type);
          tableRowsAppend.push(<><Button onClick={() => handleView(productList[i].id)}>View</Button><Link url={PRODUCT_EDIT + productList[i].shopify_product_id} monochrome="true" removeunderline="true" external><Button primary>Edit</Button></Link><Button destructive onClick={() => deleteDeactiveModalAddActive(productList[i].shopify_product_id)}>Delete</Button></>);
          //push in empty array
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);
      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = res.data;
      const pagination = setPagination(current_page, per_page, last_page, total);
      setPageinationInner(pagination);
    });
  }

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    )
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row myproducts-list justify-content-center">
          <Page title="Manage Products">
            <Card>
              <Card.Section subdued>
                <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                  <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                    <TextField
                      labelHidden
                      autoComplete="off"
                      placeholder="Enter Product Title"
                      value={productTitle || ""}
                      onChange={(value) => handleProductTitleChange(value)}
                      prefix={<Icon source={SearchMinor} color="inkLighter" />}
                      clearButton
                      onClearButtonClick={() => handleClearProductTitle()}
                    />
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 form-group">
                    <Button
                      loading={loading}
                      onClick={() => handleSearchButton()}
                      primary
                      fullWidth
                    >
                      SEARCH
                    </Button>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Select
                      placeholder="Sort"
                      onChange={handleChangeSort}
                      value={sort}
                      options={sortOptions}
                    ></Select>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Select
                      label="Show"
                      labelInline
                      onChange={handleChangePerpage}
                      value={perPage}
                      options={defaultPageOptions}
                    ></Select>
                  </div>
                </div>
              </Card.Section>

              <div className="data-wrapper">
                {pageLoad ? (
                  <div>
                    <DataTable
                      columnContentTypes={
                        [
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                        ]}
                      headings={
                        ["",
                          "Product Title",
                          "Author Name",
                          "Publication Date",
                          "Price",
                          "Type",
                          "Actions"
                        ]}
                      rows={tableRows}
                      footerContent={
                        tableRows.length > 0 ? (
                          <span>{paginationInner.showing}</span>
                        ) : null
                      }
                    />
                    <div className="paginate">
                      <Stack distribution="center">
                        {paginationActive ? (
                          <Paginate />
                        ) : null}
                      </Stack>
                    </div>
                    {tableRows.length == 0 ? (

                      <div className="Polaris-ResourceList__EmptySearchResultWrapper">
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
                          <Stack.Item>
                            <img
                              src={SEARCH_IMG}
                              alt="search_img"
                              className="Polaris-EmptySearchResult__Image"
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                              No Products found
                            </p>
                          </Stack.Item>
                        </div>
                      </div>

                    ) : null}
                  </div>

                ) : (
                  <SkeletonProductDatatable />
                )}
                {dataTableInnerLoading ? (
                  <div className="loading">
                    <Spinner size="large" />
                  </div>
                ) : null}
              </div>
            </Card>

            {/* Approval Modal */}
            <Modal
              open={approveModal}
              onClose={() => setApproveModal(false)}
              title="Do you really want to withdraw this products?"
              primaryAction={[
                {
                  content: 'Confirm',
                  onAction: () => handleWithdrawReq(),
                  loading: buttonLoading
                },
              ]}
              secondaryActions={{
                content: 'Cancel',
                onAction: () => setApproveModal(false),
              }}
            >
            </Modal>

            {/* Delete Deactive Modal */}
            <Modal
              open={deleteDeactiveModal}
              onClose={() => setDeleteDeactiveModal(false)}
              title="Do you really want to delete this product?"
              primaryAction={[
                {
                  content: 'Confirm',
                  onAction: () => handleDeleteDeactiveProduct(),
                  loading: buttonLoading
                },
              ]}
              secondaryActions={{
                content: 'Cancel',
                onAction: () => setDeleteDeactiveModal(false),
              }}
            >
              <Modal.Section>
                <FormLayout>
                  <TextStyle variation="negative">Note: If you delete this product, this product will be deleted from everywhere and will not appear in website.</TextStyle>
                  <TextStyle variation="negative">Also the orders associated with this product can be affected.</TextStyle>
                </FormLayout>
              </Modal.Section>
            </Modal>

            {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
          </Page>
        </div>
      </div>
    </>
  );
}
