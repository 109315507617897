import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Card,
  Tabs,
  FormLayout,
  TextField,
  Button,
  DisplayText,
  EmptyState,
  Modal,
  Badge,
  Icon,
  Popover,
  ChoiceList,
} from "@shopify/polaris";
import {
  MobileCancelMajor
} from '@shopify/polaris-icons';
import {
  DeleteMinor,
  CircleCancelMinor
} from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";
import "../scss/importproduct.scss";
import SkeletonViewImportProduct from "./skeletons/SkeletonViewImportProduct";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import {
  ASSET_PATH
} from "../../appconfig/Settings";
import "react-quill/dist/quill.snow.css";
import { GET_PRODUCT, REVIEW_PRODUCT, APPROVE_PRODUCT, DELETE_PRODUCT, LIST_CATEGORY, UPDATE_PRODUCT, DELETE_PRODUCT_IMAGE } from "../../apis/ApiConstant";
import axiosInstance from "../../services/axios.service";
import Editor, { modules, formats } from "../components/TextEditor";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import ToasterToggle from "../components/Toster";
import Selectreact from 'react-select'
import AuthorEditor, { authorModules, authorFormats } from "../components/TextEditorAuthor";

export default function ImportProduct(props) {
  document.title = "Import Product";
  const [pageLoad, setPageLoad] = useState(false);
  const [importProduct, setImportProduct] = useState();
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selected, setSelected] = useState(0);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteVariantModal, setDeleteVariantModal] = useState(false);
  const [deletePaperBookModal, setDeletePaperBookModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [deleteVariantId, setDeleteVariantId] = useState();
  const [deletePaperBookkId, setDeletePaperBookId] = useState();
  const [radioValue, setRadioValue] = useState();
  const [onApproveError, setOnApproveError] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [deleteimagemodal, setdeleteimagemodel] = useState(false);
  const [deleteimageid, setdeleteimageid] = useState("");
  const [btnimgdeleteloader,setbtnimgdeleteloader] = useState(false);
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  const tabs = [
    {
      id: 0,
      content: 'Product',
    },
    {
      id: 1,
      content: 'About the Book',
    },
    {
      id: 2,
      content: 'About the Author',
    },
    {
      id: 3,
      content: 'Variants',
    },
    {
      id: 4,
      content: 'Paper Back'
    },
    {
      id: 5,
      content: 'Images',
    }, 
  ];

  useEffect(() => {
    getDataFromAPI()
  }, []);

  useEffect(() => {
    setOnApproveError(false);
  }, [radioValue]);

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const approveModalAddActive = () => {
    setOnApproveError(false);
    setApproveModal(true);
  };

  const deleteModalAddActive = () => {
    setDeleteModal(true);
  };

  const getDataFromAPI = () => {
    const product_id = props.location.state;
    axiosInstance.get(GET_PRODUCT + "/" + product_id).then((res) => {
      const productList = res.data.data;
      //add collections to object
      axiosInstance.get(LIST_CATEGORY + "?per_page=25&page=1&search=&sort=atoz").then((res) => {
        const collection_data = res.data.data;

        if (productList.product_category != null && productList.product_category != undefined) {
          const collections = [];
          const selectedCollectionArray = productList.product_category.split(',');
          for (let i = 0; i < collection_data.length; i++) {
            const collectionObj = {};
            collectionObj["label"] = collection_data[i].title;
            collectionObj["value"] = collection_data[i]?.shopify_collection_id?.toString();
            collections.push(collectionObj);
          }
          productList["selected_collection"] = selectedCollectionArray;
          if (selectedCollectionArray.length > 0) {
            setSelectedCollection(selectedCollectionArray)
          }
          productList["collectionObj"] = collections;
          productList["popover"] = false;
        } else {
          const collections = [];
          for (let i = 0; i < collection_data.length; i++) {
            const collectionObj = {};
            collectionObj["label"] = collection_data[i].title;
            collectionObj["value"] = collection_data[i].shopify_collection_id.toString();
            collections.push(collectionObj);
          }
          productList["collectionObj"] = collections;
          productList["popover"] = false;
          productList["selected_collection"] = [];
        }

        productList["paper_book"] = JSON.parse(productList.paper_book);

        setImportProduct((prev) => productList);
        setPageLoad(true);
      });
    });
  }

  //handle popover
  const handlePopoverChange = () => {
    setImportProduct({ ...importProduct, popover: !importProduct.popover });
  };

  //choice list handle change
  const handleCollectionChange = (e) => {
    setSelectedCollection(Array.isArray(e) ? e.map(x => x.value) : []);
  };

  const approveProduct = () => {
    setButtonLoading(true);
    let requestObj = {};
    requestObj["product_id"] = importProduct.id;
    axiosInstance.post(APPROVE_PRODUCT, requestObj).then((res) => {
      setButtonLoading(false);
      setApproveModal(false);
      getDataFromAPI();
      setToastToggle(true);
      setToastMessage(res.data.success);
      setTimeout(() => {
        history.push("/admin/importProduct");
      }, 2000)
    }).catch((error) => {
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  }

  const deleteProduct = () => {
    setButtonLoading(true);
    axiosInstance.post(DELETE_PRODUCT + '/' + importProduct.id).then((res) => {
      setButtonLoading(false);
      setDeleteModal(false);
      getDataFromAPI();
      setToastToggle(true);
      setToastMessage(res.data.message);
      setTimeout(() => {
        history.push("/admin/importProduct");
      }, 2000)
    }).catch((error) => {
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  }

  //puts updated values in object
  const onProductDataChange = (name, value) => {
    setImportProduct({ ...importProduct, [name]: value });
  };

  //puts variant updated values in object
  const onProductVariantDataChange = (name, value, idx) => {
    importProduct["variants"][idx] = { ...importProduct["variants"][idx], [name]: value };
    setImportProduct((prev) => ({ ...prev, importProduct }));
  };

  //delete variant from object
  const deleteVariant = (mainid) => {
    importProduct["variants"].splice(deleteVariantId, 1);
    setImportProduct((prev) => ({ ...prev, importProduct }));
    setDeleteVariantModal(false);
  };


  const onProductPaperBuckDataChange = (name, value, idx) => {
    importProduct["paper_book"][idx] = { ...importProduct["paper_book"][idx], [name]: value };
    setImportProduct((prev) => ({ ...prev, importProduct }));
  };

  //delete variant from object
  const deletePaperBook = (mainid) => {
    importProduct["paper_book"].splice(deletePaperBookkId, 1);
    setImportProduct((prev) => ({ ...prev, importProduct }));
    setDeletePaperBookModal(false);
  };

  //variant delete modal
  const deleteVariantModalAddActive = (idx) => {
    setDeleteVariantId(idx);
    setDeleteVariantModal(true);
  };

  const deletePaperBookModalAddActive = (idx) => {
    setDeletePaperBookId(idx);
    setDeletePaperBookModal(true);
  };

  //update product
  const updateProductData = () => {
    setSaveBtnLoading(true);
    // const updated_by = localStorage.getItem('id');
    const selectedProductData = importProduct;
    //seleted product id
    const productId = selectedProductData.id;
    const formData = new FormData();
    // formData.append("updated_by", updated_by);
    formData.append("product_title", selectedProductData.product_title);
    formData.append("product_type", selectedProductData.product_type);
    formData.append("about_book", selectedProductData.about_book);
    formData.append("about_author", selectedProductData.about_author);
    formData.append("author_name", selectedProductData.author_name);
    formData.append("publishing_date", selectedProductData.publishing_date);
    if (selectedCollection.length > 0) {
      formData.append("product_category", selectedCollection);
    }
    let product_varient = [];
    //add variants into array
    selectedProductData.variants.forEach(item => {
      const variantObj = {};
      variantObj["id"] = item.id;
      variantObj["variant_title"] = item.variant_title;
      variantObj["sku"] = item.sku;
      variantObj["price"] = item.price;
      // variantObj["varient_url"] = item.varient_url;
      variantObj["compared_price"] = item.compared_price;
      variantObj["book_url"] = item.book_url;
      // variantObj["quantity"] = item.quantity;
      product_varient.push(variantObj);
    });
    let paper_book = [];
    //add paperbuck into array
    let paper_buck = selectedProductData.paper_book;

    if (paper_buck != null && paper_buck != "" && paper_buck.length > 0) {
      paper_buck.forEach(item => {
        const paperBuckObject = {};
        paperBuckObject["id"] =
          paperBuckObject["textFieldValue"] = item.textFieldValue;
        paperBuckObject["urlValue"] = item.urlValue;
        paperBuckObject["isAdded"] = item.isAdded;
        paperBuckObject["isDisabled"] = item.isDisabled;
        paperBuckObject["checkValidation"] = item.checkValidation;
        paper_book.push(paperBuckObject);
      });

      formData.append("paper_book", JSON.stringify(paper_book));
    }

    formData.append("product_varient", JSON.stringify(product_varient));

    axiosInstance.post(UPDATE_PRODUCT + '/' + productId, formData).then((res) => {
      setSaveBtnLoading(false);
      setisError(false);
      setToastToggle(true);
      setToastMessage(res.data.message);
      setTimeout(() => {
        history.push("/admin/importProduct");
      }, 2000)
    }).catch((error) => {
      setSaveBtnLoading(false);
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  };

  const Deleteimage = (id) => {
    setdeleteimagemodel(true);
    setdeleteimageid(id)
  }

  const deleteProductImage = async () => {
    setbtnimgdeleteloader(true);
    axiosInstance.delete(DELETE_PRODUCT_IMAGE + '/' + deleteimageid).then((res) => {
      setbtnimgdeleteloader(false);
      setToastToggle(true);
      setisError(false);
      setToastMessage(res.data.sucess);
      setdeleteimagemodel(false);
      setdeleteimageid("")
      getDataFromAPI();
    }).catch((error) => {
      setbtnimgdeleteloader(false);
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setdeleteimagemodel(false);
      setdeleteimageid("")
      setisError(true);
    })
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row import-product import-wrapper justify-content-center">
          <Page title="View Product"
            breadcrumbs={[
              {
                content: "Reports",
                onAction: handleBack,
              },
            ]}
          >
            {pageLoad ? (
              <div>
                <div className="main-wrapper">
                  <>
                    <Card>
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 width-50">
                          </div>
                        </div>
                      </div>

                      <Tabs tabs={tabs} selected={selected} onSelect={(selected) => handleTabChange(selected)}>

                        {selected == 0 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                                      <div className="product-img">
                                        {importProduct?.featured?.image != "" || importProduct.featured.image != null ? (
                                          <div className="product-img-wrapper">
                                            <img
                                              key={0}
                                              src={
                                                importProduct?.featured?.image
                                              }
                                              alt="No Image"
                                            />
                                          </div>
                                        ) : (
                                          <div className="product-img-wrapper">
                                            <img
                                              src={
                                                NO_IMAGE
                                              }
                                            ></img>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                                      <div className="product-wrapper">
                                        <FormLayout>
                                          <DisplayText
                                            element="p"
                                            size="small"
                                          >
                                            {importProduct.title}
                                          </DisplayText>
                                          <TextField label="Title" autoComplete={false} onChange={(value) => onProductDataChange("product_title", value)} value={importProduct.product_title} placeholder="Product Title" />
                                          <div>
                                            <label className="Polaris-Label__Text" style={{ "fontWeight": "600" }}>Category</label>
                                            <Selectreact
                                              className="dropdown"
                                              placeholder="Select Category"
                                              value={importProduct.collectionObj.filter(obj => selectedCollection.includes(obj.value))}
                                              options={importProduct.collectionObj}
                                              onChange={handleCollectionChange}
                                              isMulti
                                              isClearable
                                              styles={{
                                                menu: provided => ({ ...provided, zIndex: 100 })
                                              }}
                                            />
                                          </div>

                                          <TextField label="Type" autoComplete={false} onChange={(value) => onProductDataChange("product_type", value)} value={importProduct.product_type} placeholder="Product Type" />

                                          <TextField label="Original Publication Date" autoComplete={false} onChange={(value) => onProductDataChange("publishing_date", value)} value={importProduct.publishing_date} placeholder="Original Publication Date" />
                                          <div className="product-wrapper">
                                            <span style={{ "fontSize": "0.875rem", "fontWeight": "600" }}>Status:</span>&nbsp;&nbsp;&nbsp;
                                            {importProduct.status == 1 ? <Badge status="attention">Approved</Badge> : null}
                                            {importProduct.status == 0 ? <Badge progress="incomplete" status="warning">Pending</Badge> : null}
                                          </div>
                                        </FormLayout>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }
                        {selected == 1 &&

                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      {/* <span style={{ "fontSize": "0.875rem", "fontWeight": "600", "marginLeft": "25px" }}>About the Book:</span> */}
                                      <div className="description-editor">
                                        <Editor />
                                        <ReactQuill
                                          theme="snow"
                                          value={importProduct.about_book || ""}
                                          onChange={(value) =>
                                            onProductDataChange("about_book", value)
                                          }
                                          modules={modules}
                                          formats={formats}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 2 &&

                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      {/* <span style={{ "fontSize": "0.875rem", "fontWeight": "600", "marginLeft": "25px" }}>About the Author:</span> */}
                                      <div className="description-editor">
                                        <AuthorEditor />
                                        <ReactQuill
                                          theme="snow"
                                          value={importProduct.about_author || ""}
                                          onChange={(value) =>
                                            onProductDataChange("about_author", value)
                                          }
                                          modules={authorModules}
                                          formats={authorFormats}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 3 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="">
                                <div className="Polaris-DataTable variants-table">
                                  <div className="Polaris-DataTable__ScrollContainer">
                                    <table className="Polaris-DataTable__Table">
                                      <thead>
                                        <tr>
                                          {/* <th className="Polaris-DataTable__Cell"></th> */}
                                          <th className="Polaris-DataTable__Cell">
                                            Title
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            SKU
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Price
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Compare at Price
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Book Url
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Delete
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {importProduct.variants.length > 0 ? importProduct.variants.map((importProductVariant, idx) => (
                                          <tr
                                            className="Polaris-DataTable__TableRow"
                                            key={idx}
                                          >
                                            {/* <th className="Polaris-DataTable__Cell">
                                              {importProductVariant.varient_url !== null &&
                                                importProductVariant.varient_url !== undefined ? (
                                                <img
                                                  onError={(e) => {
                                                    e.target.src = NO_IMAGE;
                                                  }}
                                                  src={importProductVariant.varient_url}
                                                  alt="No Image"
                                                />
                                              ) : (
                                                <img
                                                  src={NO_IMAGE}
                                                  alt="No Image2"
                                                />
                                              )}
                                            </th> */}
                                            <td className="Polaris-DataTable__Cell">
                                              {importProductVariant.varient_title}
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductVariant.sku}
                                                  name="sku"
                                                  placeholder="sku"
                                                  onChange={(value) => onProductVariantDataChange("sku", value, idx)}
                                                />
                                              </div>
                                            </td>

                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="number"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductVariant.price}
                                                  name="price"
                                                  placeholder="Price"
                                                  onChange={(value) => onProductVariantDataChange("price", value, idx)}
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="number"
                                                  label=""
                                                  autoComplete={false}
                                                  value={
                                                    importProductVariant.compared_price
                                                  }
                                                  name="compared_at_price"
                                                  placeholder="Compare Price"
                                                  onChange={(value) => onProductVariantDataChange("compared_price", value, idx)}
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={
                                                    importProductVariant.book_url
                                                  }
                                                  name="book_url"
                                                  placeholder="Book Url"
                                                  onChange={(value) => onProductVariantDataChange("book_url", value, idx)}
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                  textAlign: "left"
                                                }}
                                              >
                                                <Button
                                                  onClick={() => deleteVariantModalAddActive(idx)}
                                                >
                                                  <Icon source={DeleteMinor} color="base" />
                                                </Button>
                                              </div>
                                            </td>
                                          </tr>
                                        )) : <tr> <td colspan={9} style={{ "textAlign": "center" }}> <EmptyState heading="No Variants Avavilable"></EmptyState> </td> </tr>}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }

                        {selected == 4 &&

                          <Card.Section>
                            <Card.Section>
                              <div className="">
                                <div className="Polaris-DataTable variants-table">
                                  <div className="Polaris-DataTable__ScrollContainer">
                                    <table className="Polaris-DataTable__Table">
                                      <thead>
                                        <tr>
                                          <th className="Polaris-DataTable__Cell">
                                            Title
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Link
                                          </th>
                                          <th className="Polaris-DataTable__Cell">
                                            Delete
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {importProduct.paper_book != null && importProduct.paper_book != "" && importProduct.paper_book.length > 0 ? importProduct.paper_book.map((importProductPaperBuck, idx) => (
                                          <tr
                                            className="Polaris-DataTable__TableRow"
                                            key={idx}
                                          >
                                            <td className="Polaris-DataTable__Cell">
                                              <div
                                                style={{
                                                  maxWidth: "200px",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductPaperBuck.textFieldValue}
                                                  name="textFieldValue"
                                                  placeholder="Title"
                                                  onChange={(value) => onProductPaperBuckDataChange("textFieldValue", value, idx)}
                                                />
                                              </div>
                                            </td>
                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "initial",
                                                }}
                                              >
                                                <TextField
                                                  type="text"
                                                  label=""
                                                  autoComplete={false}
                                                  value={importProductPaperBuck.urlValue}
                                                  name="urlValue"
                                                  placeholder="UrlValue"
                                                  onChange={(value) => onProductPaperBuckDataChange("urlValue", value, idx)}
                                                />
                                              </div>
                                            </td>

                                            <td
                                              className="Polaris-DataTable__Cell Polaris-DataTable__Cell--numeric"
                                              style={{ minWidth: "100px" }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "100px",
                                                  textAlign: "left"
                                                }}
                                              >
                                                <Button
                                                  onClick={() => deletePaperBookModalAddActive(idx)}
                                                >
                                                  <Icon source={DeleteMinor} color="base" />
                                                </Button>
                                              </div>
                                            </td>
                                          </tr>
                                        )) : <tr> <td colspan={9} style={{ "textAlign": "center" }}> <EmptyState heading="No Paper Back Avavilable"></EmptyState> </td> </tr>}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }
                        {selected == 5 &&
                          <Card.Section>
                            <Card.Section>
                              <div className="middle-section">
                                <div className="main-wrapper">
                                  <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                                      <div className="featured_images">
                                        {importProduct.featured ? (
                                          <div className="featured_wrapper">
                                            <img
                                              src={importProduct.featured.image}
                                              alt="No Image"
                                            ></img>
                                          </div>
                                        ) : <div className="featured_wrapper">
                                          <img
                                            src={importProduct.images[0].image}
                                            alt="No Image"
                                          ></img>
                                        </div>
                                        }
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                                      <div className="row product-wrapper">
                                        {(importProduct.images && importProduct.images.length > 0) && importProduct.images.map(
                                          (importProduct) =>
                                            <div className="col-lg-3 col-md-4 col-6  col-xl-2 thumb-image">
                                              <div className="thumb-wrapper thumb-cross-icon">
                                                <Button size="slim" onClick={() => Deleteimage(importProduct.id)} icon={CircleCancelMinor}></Button>
                                                <img
                                                  src={importProduct.image}
                                                  onError={(e) => {
                                                    e.target.src =
                                                      NO_IMAGE;
                                                  }}
                                                  alt="No Image"
                                                ></img>
                                                <center style={{ margin: "5px 0px" }}>
                                                  {importProduct.alt_txt.split('_').length >= 2 ?
                                                    <Badge size="small">{importProduct.alt_txt.split('_')[1]}</Badge>
                                                    : null}
                                                </center>
                                              </div>
                                            </div>
                                        )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Section>
                          </Card.Section>
                        }
                      </Tabs>
                      <div className="card-footer">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="bottom-section">
                              <Button
                                primary
                                onClick={() => approveModalAddActive(importProduct.id)}>
                                Approve
                              </Button>
                              <Button
                                destructive
                                onClick={() => deleteModalAddActive(importProduct.id)}>
                                Delete
                              </Button>
                              <Button
                                loading={saveBtnLoading}
                                primary
                                onClick={() => updateProductData()}>
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </>
                </div>

                {/* Approve Modal */}
                <Modal
                  open={approveModal}
                  onClose={() => setApproveModal(false)}
                  title="Do you want to approve this product?"
                  primaryAction={[
                    {
                      content: 'Confirm',
                      onAction: () => approveProduct(),
                      loading: buttonLoading
                    },
                  ]}
                  secondaryActions={{
                    content: 'Cancel',
                    onAction: () => setApproveModal(false),
                  }}
                >
                </Modal>

                {/* Delete Modal */}
                <Modal
                  open={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  title="Do you really want to delete this product?"
                  primaryAction={[
                    {
                      content: 'Confirm',
                      onAction: () => deleteProduct(),
                      loading: buttonLoading
                    },
                  ]}
                  secondaryActions={{
                    content: 'Cancel',
                    onAction: () => setDeleteModal(false),
                  }}
                >
                </Modal>


                {/* Delete Image Modal */}
                <Modal
                  open={deleteimagemodal}
                  onClose={() => setdeleteimagemodel(false)}
                  title="Do you really want to delete this product image?"
                  primaryAction={[
                    {
                      content: 'Confirm',
                      onAction: () => deleteProductImage(),
                      loading: btnimgdeleteloader
                    },
                  ]}
                  secondaryActions={{
                    content: 'Cancel',
                    onAction: () => setdeleteimagemodel(false),
                  }}
                >
                </Modal>


                {/* Delete Variant Modal */}
                <Modal
                  open={deleteVariantModal}
                  onClose={() => setDeleteVariantModal(false)}
                  title="Do you really want to delete this product?"
                >
                  <Modal.Section>
                    <FormLayout>
                      <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                        <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group"></div>
                        <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                          <Button
                            onClick={() => setDeleteVariantModal(false)}
                            fullWidth
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                          <Button
                            onClick={() => deleteVariant()}
                            primary
                            loading={buttonLoading}
                            fullWidth
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </FormLayout>
                  </Modal.Section>
                </Modal>


                {/* Delete Paper Book Modal */}
                <Modal
                  open={deletePaperBookModal}
                  onClose={() => setDeletePaperBookModal(false)}
                  title="Do you really want to delete this Paper Book?"
                >
                  <Modal.Section>
                    <FormLayout>
                      <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                        <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group"></div>
                        <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                          <Button
                            onClick={() => setDeletePaperBookModal(false)}
                            fullWidth
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                          <Button
                            onClick={() => deletePaperBook()}
                            primary
                            loading={buttonLoading}
                            fullWidth
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </FormLayout>
                  </Modal.Section>
                </Modal>
              </div>
            ) : (
              <SkeletonViewImportProduct />
            )}
            {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
          </Page>
        </div>
      </div>
    </>
  );
}
