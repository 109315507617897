import React from "react";
import Application from './navigation/Index'
import {Frame} from "@shopify/polaris";
import "./App.css";

function App() {
    return <Frame><Application/></Frame>
}

export default App;
