import React, { useEffect, useState } from "react";
import {
    Page,
    Stack,
    Layout,
    Card,
    DataTable,
    EmptyState,
} from "@shopify/polaris";
import {
    ASSET_PATH,
    SEARCH_IMG
} from "../../appconfig/Settings.js";
import "../scss/dashboard.scss";
import "../scss/helicore_common.scss";
import axiosInstance from "../../services/axios.service";
import moment from "moment";
import CustomDatePicker from "../components/CustomDatePicker";
import { useHistory } from "react-router-dom";
import SkeletonDashboard from "./skeletons/Dashboard";
import { DASHBOARD } from "../../apis/ApiConstant.js";

const Dashboard = () => {
    document.title = "Dashboard | Publix Press";
    const [pageLoad, setPageLoad] = useState(false);
    const [mainData, setMainData] = useState();
    const [tableRows, setTableRows] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

    useEffect(() => {
        getDataFromAPI();
    }, []);

    useEffect(() => {
        getDataFromAPI();
    }, [startDate, endDate]);

    const history = useHistory();
    const handleView = (product_id) => {
        let path = `/admin/products`;
        history.push({ pathname: path, state: product_id });
    };

    //Handle Date Change
    const handleDateChangeEvent = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        // getDataFromAPI();
    }

    //api call
    const getDataFromAPI = () => {
        const startStamp = startDate + " 00:00:00";
        const endStamp = endDate + " 23:59:59";

        axiosInstance.get(DASHBOARD + "?start_date=" + startStamp + "&end_date=" + endStamp).then((res) => {
            const productList = res.data.data.topProducts;

            if (productList.length == 0) {
                setTableRows([]);
            } else {
                let tableData = [];
                for (let i = 0; i < productList.length; i++) {
                    let tableRowsAppend = [];
                    tableRowsAppend.push(<img src={productList[i].featured ? productList[i].featured.image : NO_IMAGE} alt="Featured Image"></img>);
                    tableRowsAppend.push(productList[i].product_title);
                    tableRowsAppend.push(productList[i].author_name);
                    tableRowsAppend.push(productList[i].publishing_date);

                    if (productList[i].variants && productList[i].variants.length > 0) {
                        let total_price = 0;
                        productList[i].variants.map((item) => {
                            if (item.price != "" && item.price != null && item.price != undefined) {
                                total_price += parseFloat(item.price);
                            }
                        });
                        tableRowsAppend.push(total_price.toFixed(2));
                    } else {
                        tableRowsAppend.push("-");
                    }
                    tableRowsAppend.push(productList[i].product_type);
                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
            }
            setMainData(res.data.data);
            setPageLoad(true);
        });
    }


    return (
        <div className="dashboard_page">
            <Page title="Hello, Admin" fullWidth>
                <div className="dashbord_section">
                    <CustomDatePicker startDate={startDate} endDate={endDate} onChange={(startDate, endDate) => handleDateChangeEvent(startDate, endDate)}></CustomDatePicker>
                </div>

                <br />
                {pageLoad ? (
                    <React.Fragment>
                        <Layout>
                            <Layout.Section>
                                <Stack distribution="fillEvenly" spacing="loose" wrap={false}>
                                    <Stack.Item>
                                        <Card sectioned>
                                            <Stack alignment="center">
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Profit01.svg?v=1650516785"
                                                    height="60"
                                                    width="60"
                                                    alt="Total Profit"
                                                />
                                                <Stack.Item>
                                                    <p className="card_heading">
                                                        0.00
                                                    </p>
                                                    <p className="card_sub_heading">Total Profit</p>
                                                </Stack.Item>
                                            </Stack>
                                        </Card>
                                    </Stack.Item>

                                    <Stack.Item>
                                        <Card sectioned>
                                            <Stack alignment="center">
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Sales01.svg?v=1650516785"
                                                    height="60"
                                                    width="60"
                                                    alt="Total Sales"
                                                />
                                                <Stack.Item>
                                                    <p className="card_heading">
                                                        {mainData.totalSales}
                                                    </p>
                                                    <p className="card_sub_heading">Total Sales</p>
                                                </Stack.Item>
                                            </Stack>
                                        </Card>
                                    </Stack.Item>

                                    <Stack.Item>
                                        <Card sectioned>
                                            <Stack alignment="center">
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Orders01.svg?v=1650516785"
                                                    height="60"
                                                    width="60"
                                                    alt="Total Orders"
                                                />
                                                <Stack.Item>
                                                    <p className="card_heading">
                                                        {mainData.totalOrders}
                                                    </p>
                                                    <p className="card_sub_heading">Total Orders</p>
                                                </Stack.Item>
                                            </Stack>
                                        </Card>
                                    </Stack.Item>

                                    <Stack.Item>
                                        <Card sectioned>
                                            <Stack alignment="center">
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Products01.svg?v=1650516785"
                                                    height="60"
                                                    width="60"
                                                    alt="Products"
                                                />
                                                <Stack.Item>
                                                    <p className="card_heading">
                                                        {mainData.totalApprovedProducts}
                                                    </p>
                                                    <p className="card_sub_heading">Total Products</p>
                                                </Stack.Item>
                                            </Stack>
                                        </Card>
                                    </Stack.Item>
                                </Stack>
                            </Layout.Section>
                        </Layout>

                        <div className="dashbord_section">
                            <Layout>
                                <Layout.Section>
                                    <Stack distribution="fillEvenly" spacing="loose" wrap={false}>
                                        <Stack.Item>
                                            <Card title="Top Products" actions={[{ content: 'View More', onAction: () => handleView() }]}>
                                                <div
                                                    style={{ marginTop: "10px" }}
                                                    className="topProduct_datatable"
                                                >
                                                    {tableRows.length > 0 ? (
                                                        <DataTable
                                                            columnContentTypes={[
                                                                "text",
                                                                "text",
                                                                "text",
                                                                "text",
                                                                "text",
                                                                "text"
                                                            ]}
                                                            headings={[
                                                                "",
                                                                "Title",
                                                                "Author Name",
                                                                "Publising Date",
                                                                "Price",
                                                                "Type"]}
                                                            rows={tableRows}
                                                            verticalAlign="middle"
                                                            truncate={true}
                                                        />
                                                    ) : (
                                                        <EmptyState
                                                            centeredLayout
                                                            image={
                                                                SEARCH_IMG
                                                            }
                                                        >
                                                            <p>No Products Found</p>
                                                        </EmptyState>
                                                    )}
                                                </div>
                                            </Card>
                                        </Stack.Item>
                                    </Stack>
                                </Layout.Section>
                            </Layout>
                        </div>
                    </React.Fragment>
                ) : (
                    <SkeletonDashboard />
                )}
            </Page>
        </div>
    )
}

export default Dashboard;