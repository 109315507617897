import React, { useState, useEffect } from "react";
import {
  Card,
  DataTable,
  Icon,
  TextField,
  FormLayout,
  Page,
  Modal,
  TextStyle,
  Pagination,
  Stack,
  Select,
  Button,
  Spinner,
  Link,
  Thumbnail,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import "../scss/helicore_common.scss";
import { ASSET_PATH, defaultPageOptions, COLLECTION_VIEW, showing, sortOptions, SEARCH_IMG } from "../../appconfig/Settings";
import SkeletonManageCategory from "./skeletons/SkeletonManageCategory";
import CategoryImage from "../components/CategoryImage";
import { ADD_CATEGORY, DELETE_CATEGORY, LIST_CATEGORY, UPDATE_CATEGORY } from "../../apis/ApiConstant";
import axiosInstance from "../../services/axios.service";
import { setPagination } from "../../appconfig/AppHelper";
import Editor, { modules, formats } from "../components/TextEditor";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../scss/product.scss";
import ToasterToggle from "../components/Toster";

export default function ManageCategories() {
  document.title = "Manage Category";
  const [addCategory, setAddCategory] = useState({});
  const [updateCategory, setUpdateCategory] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [perPage, setPerPage] = useState(showing);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [onSubmitError, setOnSubmitError] = useState(false);
  const [selCategoryId, setSelCategoryId] = useState("1");
  const [sort, setSort] = useState("updatenew");
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  useEffect(() => {
    getDataFromAPI();
  }, [])

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, sort]);

  const modalAddActive = () => {
    setModal(true);
  };

  const modalUpdateActive = (obj) => {
    setUpdateModal(true);
    setSelCategoryId(obj.shopify_collection_id);
    setUpdateCategory(obj);
  };

  const modalDeleteActive = (id) => {
    setDeleteModal(true);
    setSelCategoryId(id);
  };

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleSearchButton = () => {
    setSearchLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setSearchLoading(false);
  };

  const handleChangeSort = (value) => {
    paginationInner.page = 1;
    setSort(value);
  };

  //makes object for add category data
  const onHandleChange = (name, value) => {
    setAddCategory({ ...addCategory, [name]: value });
  };

  //makes object for add category data
  const onUpdateHandleChange = (name, value) => {
    setUpdateCategory({ ...updateCategory, [name]: value });
  };

  //images variables
  let getImagesRefFromAddImage = null;
  let getUpdatedRefImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  }
  const getUpdatedImage = (ref) => {
    getUpdatedRefImage = ref;
  }

  //category list api
  const getDataFromAPI = () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    axiosInstance.get(LIST_CATEGORY + "?per_page=" + perPage + "&page=" + page + "&search=" + search + "&sort=" + sort).then((res) => {
      const categoryList = res.data.data;

      if (categoryList.length == 0) {
        setTableRows([]);
        setPaginationActive(false);
      } else {
        let tableData = [];
        for (let i = 0; i < categoryList.length; i++) {
          let tableRowsAppend = [];

          tableRowsAppend.push(<div className="img-list-wrapper"><img src={categoryList[i].image ? categoryList[i].image : NO_IMAGE} alt="Category Image"></img></div>);
          tableRowsAppend.push(<Link url={COLLECTION_VIEW + categoryList[i].shopify_category_handle} monochrome={true} external>{categoryList[i].title}</Link>);
          tableRowsAppend.push(categoryList[i].product_count ? categoryList[i].product_count : 0);
          tableRowsAppend.push(<><Button primary onClick={() => modalUpdateActive(categoryList[i])}>Edit</Button> <Button destructive onClick={() => modalDeleteActive(categoryList[i].shopify_collection_id)}>Delete</Button> </>);
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);
      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = res.data;
      const pagination = setPagination(current_page, per_page, last_page, total);
      setPageinationInner(pagination);
    });
  };

  //Add New Category
  const submitCategory = () => {
    // Get Images from the Add Image Component
    const image = getImagesRefFromAddImage?.getImages();
    if (window.cn(addCategory.title)) {
      setOnSubmitError(true);
    } else {
      setSubmitLoading(true);
      //formdata object
      const formData = new FormData();
      // Get Images from the Add Image Component
      const image = getImagesRefFromAddImage?.getImages();
      //send products other data in formdata

      Object.keys(addCategory).forEach((item) => {

        formData.append(item, addCategory[item]);
      });


      if (image.length > 0) {
        //send category image in formdata
        formData.append("image", image[0]);
      }

      //api call
      axiosInstance.post(ADD_CATEGORY, formData).then((res) => {
        setSubmitLoading(false);
        setModal(false);
        getDataFromAPI();
        setToastToggle(true);
        setToastMessage(res.data.message);
        setAddCategory({});
      }).catch((error) => {
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      })
    }
  };

  //Update existing category
  const updateExistingCategory = () => {
    setSubmitLoading(true);
    // Get Images from the Add Image Component
    const updatedImage = getUpdatedRefImage?.getImages();

    //formdata object
    const formData = new FormData();

    if (updatedImage.length > 0) {
      //send category image in formdata
      formData.append("image", updatedImage[0]);
    }

    formData.append("title", updateCategory.title);
    formData.append("description", updateCategory.description);

    //api call
    axiosInstance.post(UPDATE_CATEGORY + "/" + selCategoryId, formData).then((res) => {
      setSubmitLoading(false);
      setUpdateModal(false);
      getDataFromAPI();
      setToastToggle(true);
      setToastMessage(res.data.message);
      setUpdateCategory({});
    }).catch((error) => {
      setSubmitLoading(false);
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  };

  //Delete Category
  const deleteCategory = () => {
    //api call
    axiosInstance.post(DELETE_CATEGORY + "/" + selCategoryId).then((res) => {
      setSubmitLoading(false);
      setDeleteModal(false);
      getDataFromAPI();
      setToastToggle(true);
      setToastMessage(res.data.message);
    }).catch((error) => {
      setSubmitLoading(false);
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    });
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    )
  }

  return (
    <>
      <div className="category_list_page">
        <Page
          fullWidth
          title="Manage Category"
          primaryAction={{
            content: "Add Category",
            onAction: modalAddActive,
          }}
        >
          <Card>
            <Card.Section subdued>
              <Stack>
                <Stack.Item fill>
                  <TextField
                    labelHidden
                    autoComplete="off"
                    placeholder="Enter Product Title"
                    value={search || ""}
                    onChange={(value) => handleSearch(value)}
                    prefix={<Icon source={SearchMinor} color="inkLighter" />}
                    clearButton
                    onClearButtonClick={() => handleClearSearch()}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    loading={searchLoading}
                    onClick={() => handleSearchButton()}
                    primary
                    fullWidth
                  >
                    SEARCH
                  </Button>
                </Stack.Item>
                <Stack.Item>
                  <Select
                    placeholder="Sort"
                    onChange={handleChangeSort}
                    value={sort}
                    options={sortOptions}
                  ></Select>
                </Stack.Item>
                <Stack.Item>
                  <Select
                    label="Show"
                    labelInline
                    onChange={handleChangePerpage}
                    value={perPage}
                    options={defaultPageOptions}
                  ></Select>
                </Stack.Item>
              </Stack>
            </Card.Section>
            <div className="data-wrapper">
              {pageLoad ? (
                <div>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    truncate={true}
                    headings={[
                      "Image",
                      "Category Name",
                      "Number of Products",
                      "Actions",
                    ]}
                    rows={tableRows}
                    footerContent={
                      tableRows.length > 0 ? (
                        <span>{paginationInner.showing}</span>
                      ) : null
                    }
                    verticalAlign="middle"
                  />
                  {tableRows.length > 0 ? null : (
                    <div className="search_div">
                      <img
                        src={SEARCH_IMG}
                        alt="search_img"
                      />
                      <div>
                        <span className="content_span">No Categories found</span>
                      </div>
                    </div>
                  )}
                  <div className="paginate">
                    <Stack distribution="center">
                      {paginationActive ? (
                        <Paginate />
                      ) : null}
                    </Stack>
                  </div>
                </div>
              ) : (
                <SkeletonManageCategory></SkeletonManageCategory>
              )}
              {dataTableInnerLoading ? (
                <div className="loading">
                  <Spinner size="large" />
                </div>
              ) : null}
            </div>
          </Card>

          {/*Add Category Modal*/}
          <Modal
            open={modal}
            onClose={() => setModal(false)}
            title="Add New Category"
          >
            <Modal.Section>
              <FormLayout>
                {onSubmitError ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon"><span className="Polaris-Icon"><span className="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path></svg></span></div>
                    <span>Title is required.</span>
                  </div>) : null
                }
                <TextField
                  label="Category Name"
                  autoComplete="off"
                  placeholder="Name"
                  value={addCategory.title || ""}
                  onChange={(value) => onHandleChange("title", value)}
                />
                <Editor />
                <ReactQuill
                  theme="snow"
                  value={addCategory.description || ""}
                  onChange={(value) => onHandleChange("description", value)}
                  modules={modules}
                  formats={formats}
                />
                <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
                <TextStyle variation="warning">Note: Upload Image with size of size (1024*1024).</TextStyle>
                <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                  <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12"></div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group"></div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Button
                      onClick={() => setModal(false)}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Button
                      onClick={() => submitCategory()}
                      primary
                      loading={submitLoading}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </FormLayout>
            </Modal.Section>
          </Modal>


          {/*Update Category Modal*/}
          {!window.cn(updateCategory) ?
            <Modal
              open={updateModal}
              onClose={() => setUpdateModal(false)}
              title="Edit Category"
            >
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label="Category Name"
                    autoComplete="off"
                    placeholder="Name"
                    value={updateCategory.title || ""}
                    onChange={(value) => onUpdateHandleChange("title", value)}
                  />
                  <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label id="PolarisTextField12Label" for="PolarisTextField12" className="Polaris-Label__Text">Description</label></div></div>
                  <Editor />
                  <ReactQuill
                    theme="snow"
                    value={updateCategory.description || ""}
                    onChange={(value) => onUpdateHandleChange("description", value)}
                    modules={modules}
                    formats={formats}
                  />
                  <div>
                    <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label id="PolarisTextField12Label" for="PolarisTextField12" className="Polaris-Label__Text">Image</label></div></div>
                    <Thumbnail
                      source={updateCategory.image}
                      size="large"
                      alt="Black choker necklace"
                    />
                  </div>
                  <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label id="PolarisTextField12Label" for="PolarisTextField12" className="Polaris-Label__Text">Change Image</label></div></div>
                  <CategoryImage getImagesFromAddImage={getUpdatedImage} />
                  <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                    <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12"></div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group"></div>
                    <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                      <Button
                        onClick={() => setUpdateModal(false)}
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                      <Button
                        onClick={() => updateExistingCategory()}
                        primary
                        loading={submitLoading}
                        fullWidth
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </FormLayout>
              </Modal.Section>
            </Modal>
            : null}

          {/* Delete Modal */}
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            title="Do you really want to delete this category?"
            primaryAction={[
              {
                content: 'Confirm',
                onAction: () => deleteCategory(),
                loading: submitLoading
              },
            ]}
            secondaryActions={{
              content: 'Cancel',
              onAction: () => setDeleteModal(false),
            }}
          >
            <Modal.Section>
              <FormLayout>
                <TextStyle variation="negative">Note: If you delete this category, all the products associated with this category will be deleted.</TextStyle>
              </FormLayout>
            </Modal.Section>
          </Modal>

          {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
        </Page>
      </div>
    </>
  );
}
