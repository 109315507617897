import React, { useState, useContext } from "react";
import {
    Card,
    Layout,
    Page,
    TextField,
    Stack,
    Button,
} from "@shopify/polaris";
import { CHANGE_PASSWORD } from "../../apis/ApiConstant";
import axiosInstance from "../../services/axios.service";
import ToasterToggle from "../components/Toster";
import { AuthContext } from "../../services/AuthProvider";
import { Buffer } from "buffer";

export default function ChangePassword() {
    document.title = "Change Password";
    const auth = useContext(AuthContext);
    const [changePassword, setChangePassword] = useState({});
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [isError, setisError] = useState(false);

    //check field validation
    const [validation, setValidation] = useState({
        old_password: false,
        password: false,
        confirm_password: false,
    });

    //makes object for add product data
    const onHandleChange = (name, value) => {
        if (changePassword[name] !== "" || changePassword[name] !== undefined) {
            setValidation({ ...validation, [name]: false });
        }
        setChangePassword({ ...changePassword, [name]: value });
    };

    //checks validation
    const validate = () => {
        let validate = true;

        //Validation
        if (window.cn(changePassword.old_password)) {
            setValidation((prev) => ({ ...prev, old_password: true }))
            validate = false;
        } if (window.cn(changePassword.password)) {
            setValidation((prev) => ({ ...prev, password: true }))
            validate = false;
        } if (window.cn(changePassword.confirm_password)) {
            setValidation((prev) => ({ ...prev, confirm_password: true }))
            validate = false;
        }

        if (!validate) {
            window.scrollTo(200, 0);
        }

        return validate;
    }

    //On Submit Click
    const onCHnagePasswordSubmit = async () => {
        //formdata object
        const formData = new FormData();

        //is validated or not
        const isValidate = validate();
        if (isValidate) {
            setLoading(true);
            let finalObj = {};
            let passwordBuff = Buffer.from(changePassword.password).toString('base64');
            let confirmPasswordBuff = Buffer.from(changePassword.confirm_password).toString('base64');
            finalObj["password"] = passwordBuff;
            finalObj["confirm_password"] = confirmPasswordBuff;
            finalObj["old_password"] = changePassword.old_password;
            finalObj["id"] = 1;
            //api call
            axiosInstance.post(CHANGE_PASSWORD, finalObj).then((res) => {
                setLoading(false);
                setToastToggle(true);
                setisError(false);
                setToastMessage(res.data.message);
                if (res) {
                    setTimeout(() => {
                        auth.signout();
                    }, 2000)
                }
            }).catch((error) => {
                setToastToggle(true);
                if (error.response.data.error) {
                    setToastMessage(error.response.data.error);
                } else {
                    setToastMessage("Something went wrong");
                }
                setisError(true);
                setLoading(false);
            })
        }
    };

    return (
        <>
            <Page
                title="Add Product"
            >
                <div className="page-wrapper product-container">
                    <Layout>
                        <Layout.Section>
                            <Card sectioned>
                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Old Password</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"
                                        placeholder="Old Password"
                                        error={validation.old_password ? "Old Password can't be blank." : false}
                                        value={changePassword.old_password || ""}
                                        onChange={(value) => onHandleChange("old_password", value)}
                                        showCharacterCount
                                    />

                                </div>
                                <br/>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">New Password</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"
                                        placeholder="New Password"
                                        error={validation.password ? "Password can't be blank." : false}
                                        value={changePassword.password || ""}
                                        onChange={(value) => onHandleChange("password", value)}
                                        type="password"
                                        showCharacterCount
                                    />
                                    
                                </div>
                                <br/>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Confirm Password</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"
                                        placeholder="Confirm Password"
                                        error={validation.confirm_password ? "Confirm Password can't be blank." : false}
                                        value={changePassword.confirm_password || ""}
                                        onChange={(value) => onHandleChange("confirm_password", value)}
                                        type="password"
                                        showCharacterCount
                                    />
                                </div>

                            </Card>
                        </Layout.Section>

                        <Layout.Section>
                            <Stack>
                                <div>
                                    <Button
                                        loading={loading}
                                        primary onClick={() => onCHnagePasswordSubmit()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Stack>
                        </Layout.Section>
                    </Layout>
                </div>
                {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
            </Page>
        </>
    );
}
