import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    DataTable,
    Page,
    Filters,
    Spinner,
    Stack,
    Select,
    Link,
    Badge,
    Button,
    Pagination,
    ChoiceList
} from "@shopify/polaris";
import "../scss/helicore_common.scss";
import "../scss/manage_order.scss";
import {
    defaultPageOptions,
    showing,
    OrderStatus,
    SEARCH_IMG
} from "../../appconfig/Settings";
import moment from "moment";
import CustomDatePicker from "../components/CustomDatePicker";
import SkeletonManageOrder from "./skeletons/SkeletonManageOrder";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../services/axios.service";
import { formatDateTime, setPagination } from "../../appconfig/AppHelper";
import { LIST_ORDER } from "../../apis/ApiConstant";

export default function ManageOrder() {
    document.title = "Manage Orders | Publix Press";

    const [pageLoad, setPageLoad] = useState(false);
    const [paginationActive, setPaginationActive] = useState(false);
    const [perPage, setPerPage] = useState(showing);
    const [paginationInner, setPageinationInner] = useState(setPagination());
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [tableRows, setTableRows] = useState([]);
    const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
    const [orderStatusSelected, setOrderStatusSelected] = useState([]);
    const [shipStatusSelected, setShipStatusSelected] = useState([]);

    useEffect(() => {
        getOrderList();
    }, []);

    useEffect(() => {
        getOrderList();
    }, [perPage, search, startDate, endDate]);

    useEffect(() => {
        setSearch("");
        getOrderList();
    }, [orderStatusSelected, shipStatusSelected]);

    //perpage change
    const handleChangePerpage = (value) => {
        paginationInner.page = 1;
        setPerPage(value);
    };

    //change order ststus
    const handleOrderStatusChange = useCallback((value) => setOrderStatusSelected(value), [],);

    //search
    const handleSearch = (value) => {
        setSearch(value);
    };

    //clear search
    const handleClearSearch = () => {
        setSearch("");
    };

    //Handle Date Change
    const handleDateChangeEvent = (start, end) => {
        paginationInner.page = 1;
        setStartDate(start);
        setEndDate(end);
        getOrderList();
    }

    //view order
    const history = useHistory();
    const handleViewOrder = (order_id) => {
        let path = `/admin/view/manageOrders`;
        history.push({ pathname: path, state: order_id });
    };

    //get order list
    const getOrderList = async () => {
        try {
            if (pageLoad) setDataTableInnerLoading(true);

            const { page } = paginationInner;
            const response = await axiosInstance(LIST_ORDER + "?per_page=" + perPage + "&search=" + search + "&payment_status=" + orderStatusSelected);
            const OrderList = response.data.data;
         

            if (OrderList.length == 0) {
                setTableRows([]);
                setPaginationActive(false);
            } else {
                let tableData = [];
                for (let i = 0; i < OrderList.length; i++) {
                    const customer = JSON.parse(OrderList[i].customer);
                    let tableRowsAppend = [];
                    tableRowsAppend.push(<b><Link monochrome={true} onClick={() => handleViewOrder(OrderList[i].shopify_order_id)}>{OrderList[i].name}</Link></b>);
                    tableRowsAppend.push(OrderList[i].shopify_order_id);
                    tableRowsAppend.push(formatDateTime(OrderList[i].created_at));
                    tableRowsAppend.push(!window.cn(customer) ? customer.first_name + " " + customer.last_name : "");
                    tableRowsAppend.push(<><Badge progress="complete">{OrderList[i].financial_status.charAt(0).toUpperCase() + OrderList[i].financial_status.slice(1)}</Badge></>);
                    tableRowsAppend.push(<span style={{textTransform:"capitalize"}}>{OrderList[i].gateway}</span>);
                    tableRowsAppend.push("$"+OrderList[i].total_price);
                   
                    //push in empty array
                    tableData.push(tableRowsAppend);
                }
                setTableRows(tableData);
                setPaginationActive(true);
            }
            setDataTableInnerLoading(false);
            setPageLoad(true);
            //Get Responce Data and Set respectively
            const { current_page, total, per_page, last_page } = response.data;
            const pagination = setPagination(current_page, per_page, last_page, total);
            setPageinationInner(pagination);
        } catch (error) { }
    }
    const filters = [
        {
            key: "order_status",
            label: "Payment Status",
            filter: (
                <ChoiceList
                    title="Payment Status"
                    titleHidden
                    choices={OrderStatus}
                    selected={orderStatusSelected || []}
                    onChange={handleOrderStatusChange}
                />
            ),
            shortcut: true,
        },
    ];
    const appliedFilters = [];


    const Paginate = () => {
        return (
            <>
                <Pagination
                    previousTooltip="Previous"
                    hasPrevious={paginationInner.hasPrevious}
                    onPrevious={() => handleChangePage("Previous")}
                    hasNext={paginationInner.hasNext}
                    nextTooltip="Next"
                    onNext={() => handleChangePage("Next")}
                />
            </>
        )
    }

    const handleChangePage = (action) => {
        action === "Next" ? paginationInner.page++ : paginationInner.page--;
        getOrderList();
    };

    return (
        <div className="order_list_page">
            <Page fullWidth title="Manage Orders">
                <CustomDatePicker startDate={startDate} endDate={endDate} onChange={(startDate, endDate) => handleDateChangeEvent(startDate, endDate)}></CustomDatePicker>
                <div className="order_section">
                    <Card>
                        <Card.Section subdued>
                            <Stack distribution="trailing">
                                <Stack.Item fill>
                                    <Filters
                                        queryValue={search}
                                        filters={[]}
                                        appliedFilters={appliedFilters}
                                        onQueryChange={handleSearch}
                                        onQueryClear={handleClearSearch}
                                        onClearAll={() => { }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Select
                                        label="Show"
                                        labelInline
                                        onChange={handleChangePerpage}
                                        value={perPage}
                                        options={defaultPageOptions}
                                    ></Select>
                                </Stack.Item>
                            </Stack>
                        </Card.Section>
                        <div className="data-wrapper">
                            {pageLoad ? (
                                <div className="orders-list">
                                    <DataTable
                                        columnContentTypes={[
                                            "text",
                                            "text",
                                            "text",
                                            "text",
                                            "text",
                                            "text",
                                            "text"

                                        ]}
                                        headings={[
                                            "Order No.",
                                            "Shopify Order Id",
                                            "Order Date",
                                            "Customer",
                                            "Payment Status",
                                            "Payment Method",
                                            "Order Amount",

                                        ]}
                                        rows={tableRows}
                                        footerContent={
                                            tableRows.length > 0 ? (
                                                <span>{paginationInner.showing}</span>
                                            ) : null
                                        }
                                        verticalAlign="middle"
                                    />

                                    <div className="paginate">
                                        <Stack distribution="center">
                                            {paginationActive ? (
                                                <Paginate />
                                            ) : null}
                                        </Stack>
                                    </div>
                                    {tableRows.length == 0 ? (

                                        <div className="Polaris-ResourceList__EmptySearchResultWrapper">
                                            <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
                                                <Stack.Item>
                                                    <img
                                                        src={SEARCH_IMG}
                                                        alt="search_img"
                                                        className="Polaris-EmptySearchResult__Image"
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                                                        No Orders found
                                                    </p>
                                                </Stack.Item>
                                            </div>
                                        </div>

                                    ) : null}
                                </div>

                            ) : (
                                <SkeletonManageOrder />
                            )}
                            {dataTableInnerLoading ? (
                                <div className="loading">
                                    <Spinner size="large" />
                                </div>
                            ) : null}
                        </div>
                    </Card>
                </div>
            </Page>
        </div>
    );
}