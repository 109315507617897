import React, { Component } from "react";
import {
  Card,
  SkeletonBodyText,
  DataTable,
  Stack,
  Layout,
} from "@shopify/polaris";
import { ASSET_PATH } from "../../../appconfig/Settings";

const tableRows = [];
class SkeletonDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.topProductList();
  }
  topProductList() {
    tableRows.length = 0;
    //loop iterate
    for (let i = 0; i < 2; i++) {
      const tableRowsAppend = [];
      tableRowsAppend.push(
        <img
          src={ASSET_PATH + "no-image.jpg?v=1654230497"}
          height="80"
          width="80"
          alt="No-Display"
        />
      );
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);
      tableRowsAppend.push(<SkeletonBodyText lines={1} />);

      //Table Row append in array
      tableRows.push(tableRowsAppend);
    }
  }
  render() {
    return (
      <div style={{ marginLeft: "-22px" }}>
        <div className="summary">
          <Layout.Section>
            <Stack distribution="fillEvenly" spacing="loose" wrap={false}>
              <Stack.Item>
                <Card sectioned>
                  <Stack alignment="center">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Profit01.svg?v=1650516785"
                      height="72"
                      width="72"
                      alt="Total Profit"
                    />
                    <Stack.Item>
                      <p className="card_heading" style={{maxWidth:"70px"}}>
                        <SkeletonBodyText lines={1}/>
                      </p>
                      <p className="card_sub_heading">Total Profit</p>
                    </Stack.Item>
                  </Stack>
                </Card>
              </Stack.Item>

              <Stack.Item>
                <Card sectioned>
                  <Stack alignment="center">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Sales01.svg?v=1650516785"
                      height="72"
                      width="72"
                      alt="Total Sales"
                    />
                    <Stack.Item>
                      <p className="card_heading" style={{maxWidth:"70px"}}>
                        <SkeletonBodyText lines={1} />
                      </p>
                      <p className="card_sub_heading">Total Sales</p>
                    </Stack.Item>
                  </Stack>
                </Card>
              </Stack.Item>

              <Stack.Item>
                <Card sectioned>
                  <Stack alignment="center">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Total_Orders01.svg?v=1650516785"
                      height="72"
                      width="72"
                      alt="Total Orders"
                    />
                    <Stack.Item>
                      <p className="card_heading" style={{maxWidth:"70px"}}>
                        <SkeletonBodyText lines={1} />
                      </p>
                      <p className="card_sub_heading">Total Orders</p>
                    </Stack.Item>
                  </Stack>
                </Card>
              </Stack.Item>

              <Stack.Item>
                <Card sectioned>
                  <Stack alignment="center">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0606/1532/7932/files/Products01.svg?v=1650516785"
                      height="72"
                      width="72"
                      alt="Imported Products"
                    />
                    <Stack.Item>
                      <p className="card_heading" style={{maxWidth:"70px"}}>
                        <SkeletonBodyText lines={1} />
                      </p>
                      <p className="card_sub_heading">Total Products</p>
                    </Stack.Item>
                  </Stack>
                </Card>
              </Stack.Item>
            </Stack>
          </Layout.Section>
        </div>
        <div className="chart-summary">
          <Layout.Section>
            <Stack distribution="fillEvenly" spacing="loose" wrap={false}>
              <Stack.Item>
                <Card title="Top Products" sectioned>
                  <div className="topProduct_datatable">
                    <DataTable
                      columnContentTypes={[
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                        "text"
                      ]}
                      headings={[
                        "",
                        "Title",
                        "Author Name",
                        "Publising Date",
                        "Price",
                        "Type"]}
                      rows={tableRows}
                      verticalAlign="middle"
                      truncate={true}
                    />
                  </div>
                </Card>
              </Stack.Item>
            </Stack>
          </Layout.Section>
        </div>
      </div>
    );
  }
}
export default SkeletonDashboard;
