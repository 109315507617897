import { APP_BASE_URL } from "../appconfig/Settings";

// Login
export const LOGIN = APP_BASE_URL + 'login';

// Dashboard
export const DASHBOARD = APP_BASE_URL + 'app/dashboard';
export const NAV_COUNT = APP_BASE_URL + 'app/total-count';

// User
export const LIST_ALL_USER = APP_BASE_URL + 'app/list-user-all';

// Product
export const ADD_PRODUCT = APP_BASE_URL + 'app/add-product';
export const UPDATE_PRODUCT = APP_BASE_URL + 'app/update-product';
export const IMPORT_PRODUCT = APP_BASE_URL + 'app/import-product';
export const MY_PRODUCT = APP_BASE_URL + 'app/my-product';
export const GET_PRODUCT = APP_BASE_URL + 'app/product-by-id';
export const DELETE_PRODUCT = APP_BASE_URL + 'app/delete-product';
export const REVIEW_PRODUCT = APP_BASE_URL + 'app/review-product';
export const APPROVE_PRODUCT = APP_BASE_URL + 'app/approve-product';
export const DELETE_PRODUCT_BY_SHOPIFY_ID = APP_BASE_URL + 'app/shopify-product-delete';

// Category
export const ADD_CATEGORY = APP_BASE_URL + 'app/add-category';
export const UPDATE_CATEGORY = APP_BASE_URL + 'app/update-category';
export const DELETE_CATEGORY = APP_BASE_URL + 'app/delete-category';
export const LIST_CATEGORY = APP_BASE_URL + 'app/list-category';
export const LIST_CATEGORY_PRODUCT = APP_BASE_URL + 'app/list-category-product';

// Customer
export const LIST_CUSTOMER = APP_BASE_URL + 'app/list-customer';

// Order 
export const LIST_ORDER = APP_BASE_URL + 'app/list-order';
export const ORDER_VIEW = APP_BASE_URL + 'app/get-order';

//Subscriber
export const LIST_SUBSCRIBER = APP_BASE_URL + 'app/list-subscriber';

//Change Password
export const CHANGE_PASSWORD = APP_BASE_URL + 'app/change-password';

export const DELETE_PRODUCT_IMAGE = APP_BASE_URL + 'app/delete-image'; 
