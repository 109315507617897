import React, { useState } from "react";
import {
    TextField,
    Layout,
    Icon,
    Button,
    Tag,
    Form,
    Select,
} from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import { varientOptions } from "../../appconfig/Settings";

export default function SelectVariants({
    variantData,
    removeVarientFromDom,
    handleOptionValueChange,
    updateTextFiled,
    deleteOptionValueChange,
}) {

    return (
        <>
            <div>

                <div className="option-container">
                    <div className="option-wrapper">

                        <div className="row" style={{ alignItems: "end" }}>
                            <div className="col-lg-10  col-md-4 col-sm-12 col-xs-12">
                                <Form
                                    onSubmit={() =>
                                        handleOptionValueChange(true)
                                    }
                                >
                                    <TextField
                                        autoComplete="off"
                                        label="Option Values"
                                        value={variantData.textFieldValue || ""}
                                        onChange={(value) =>
                                            updateTextFiled(value)
                                        }
                                        disabled={variantData.isDisabled || variantData.optionName == "" ? true : false}
                                        helpText="Please press enter to add this variant"
                                    />
                                </Form>
                            </div>
                            <div className="col-lg-2  col-md-4 col-sm-12 col-xs-12" style={{ marginBottom: '25px' }}>
                                <Button monochrome type="button" onClick={removeVarientFromDom}>
                                    <Icon source={DeleteMinor} color="black" />
                                </Button>
                            </div>
                        </div>

                        <div style={{ display: "table-row" }}>
                            {variantData.optionValue.map((colorName) => {
                                return (
                                    <div
                                        style={{
                                            marginRight: "10px",
                                            marginTop: "10px",
                                            float: "left",
                                        }}
                                    >
                                        <Tag onRemove={() => deleteOptionValueChange(colorName)} disabled={false}>
                                            {colorName}
                                        </Tag>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
