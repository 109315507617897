import React, { useState, useEffect } from "react";
import {
    Card,
    Layout,
    Page,
    TextStyle,
    Stack,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../services/axios.service";
import { ORDER_VIEW } from "../../apis/ApiConstant";
import "../scss/manage_order.scss";
import { formatDateTime } from "../../appconfig/AppHelper";
import SkeletonPage from "../pages/skeletons/SkeletonPage";

export default function ViewOrder(props) {

    const [lineItem, setLineItem] = useState([]);
    const [orderData, setOrderData] = useState();
    const [productData, setProductData] = useState();
    const [customerData, setCustomerData] = useState();
    const [shippingData, setShippingData] = useState();
    const [billingData, setBillingData] = useState();

    document.title = `Order ${!window.cn(orderData) && orderData ? '- ' + orderData.name : ''} | Publix Press`;

    useEffect(() => {
        getDataFromAPI()
    }, []);

    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    };

    //get image
    let getLabelImage = null;
    const getLabelImageRef = (ref) => {
        getLabelImage = ref;
    }

    //get order data
    const getDataFromAPI = () => {
        const order_id = props.location.state;
        axiosInstance.get(ORDER_VIEW + "/" + order_id).then((res) => {
            //Destructure Data
            const { lineitems, product, customer, shipping_address, billing_address } = res.data.data;
            if (!window.cn(lineitems) && lineitems.length > 0) {
                setLineItem(lineitems);
            }
            setOrderData(res.data.data);
            setProductData(product);
            setCustomerData(JSON.parse(customer));
            setShippingData(JSON.parse(shipping_address));
            setBillingData(JSON.parse(billing_address));

        });
    }

    return (
        <>
            {!window.cn(orderData) && orderData ?
                <Page
                    title={!window.cn(orderData) && orderData ? orderData.name : null}
                    titleMetadata={!window.cn(customerData) && customerData ? <>&nbsp;&nbsp;&nbsp;&nbsp;(Order ID - {orderData.shopify_order_id})</> : null}
                    subtitle={!window.cn(orderData) ? formatDateTime(orderData.created_at) : null}
                    fullWidth
                    breadcrumbs={[
                        {
                            content: "Order",
                            onAction: handleBack,
                        },
                    ]}
                >
                    <Layout>
                        <Layout.Section>
                            <Card title="Products" sectioned>
                                <div className="">
                                    <div className="Polaris-DataTable variants-table">
                                        <div className="Polaris-DataTable__ScrollContainer">
                                            <table className="Polaris-DataTable__Table">
                                                <thead>
                                                    <tr>
                                                        <th className="Polaris-DataTable__Cell">
                                                            Product
                                                        </th>
                                                        <th className="Polaris-DataTable__Cell">
                                                           Net Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lineItem.map((item, index) => (
                                                        <tr
                                                            className="Polaris-DataTable__TableRow"
                                                        >
                                                            <td className="Polaris-DataTable__Cell">
                                                                <p>{item.title}</p>
                                                                {!window.cn(item.variant_title) ? <> <TextStyle variation="subdued">{item.variant_title}</TextStyle><br /> </> : null}
                                                                <TextStyle variation="subdued">SKU: {item.sku}</TextStyle><br />
                                                                {!window.cn(item.warehouse_location) ? <><TextStyle variation="subdued">Location:  {item.warehouse_location.replace(/[AISLE_SHELFBOX]/g, '')}</TextStyle><br /></> : null}
                                                            </td>
                                                            <td className="Polaris-DataTable__Cell">
                                                                <span>${item.item_price}</span>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card title="Payment" sectioned>
                                <Stack distribution="fillEvenly">
                                    <span style={{ "font-size": "0.885rem" }}>Discount</span>
                                    <span style={{ "font-size": "0.885rem", marginLeft: "67%" }}>${!window.cn(orderData) && orderData ? orderData.total_discounts : null}</span>
                                </Stack>
                                <Stack distribution="fillEvenly">
                                    <span style={{ "font-size": "0.885rem" }}><b>Total</b></span>
                                    <span style={{ "font-size": "0.885rem", marginLeft: "67%" }}><b>${!window.cn(orderData) && orderData ? orderData.total_price : null}</b></span>
                                </Stack>
                            </Card>
                        </Layout.Section>

                        <Layout.Section secondary>
                            <Card title="Customer">
                                <Card.Section>
                                    <span>{!window.cn(customerData) && customerData ? customerData.first_name + ' ' + customerData.last_name : null}</span>
                                </Card.Section>
                                <Card.Section title="CONTACT INFORMATION">
                                    <span style={{ "font-size": "0.885rem" }}>Email: {!window.cn(customerData) && customerData ? customerData.email : null}</span><br />
                                    <span style={{ "font-size": "0.885rem" }}>Phone: {!window.cn(customerData) && customerData ? customerData.phone : null}</span>
                                </Card.Section>
                                {JSON.stringify(shippingData) === JSON.stringify(billingData) ?
                                    <Card.Section title="BILLING ADDRESS">
                                        <span style={{ "font-size": "0.885rem" }}>Same as shipping address</span>
                                    </Card.Section>
                                    :
                                    <Card.Section title="BILLING ADDRESS">
                                        <span style={{ "font-size": "0.885rem" }}>{!window.cn(billingData) && billingData ? billingData.name : null}</span><br />
                                        <span style={{ "font-size": "0.885rem" }}>{!window.cn(billingData) && billingData ? billingData.address1 : null}</span><br />
                                        <span style={{ "font-size": "0.885rem" }}>{!window.cn(billingData) && billingData ? billingData.zip : null}</span><br />
                                        <span style={{ "font-size": "0.885rem" }}>{!window.cn(billingData) && billingData ? billingData.province : null}</span>
                                    </Card.Section>
                                }
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
                : <SkeletonPage />}
        </>
    )
}