import axios from 'axios';
import {APP_BASE_URL} from '../appconfig/Settings';

const instance = axios.create({
    baseURL: `${APP_BASE_URL}/api`,
    mode: "cors",
    headers: {}
});

//Check Token Exist or not
if (localStorage.hasOwnProperty("token")) {
    instance.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('token');
}

instance.interceptors.response.use(function (response) {
    if (response) {
        if (response.data && response.data.token) {
            // append your request headers with an authenticated token
            response.headers["Authorization"] = `Bearer ${response.data.token}`;
        }
    }
    if (localStorage.hasOwnProperty("token")) {
        const token = localStorage.getItem("token");
        // append your request headers with an authenticated token
        response.headers["Authorization"] = `Bearer ${token}`;
    }
    return response;
}, function (error) {
    if (typeof error.response != 'undefined' && error.response.status === 401) {
        if(localStorage.getItem('user_type') == "Admin" || localStorage.getItem('user_type') == "SubAdmin"){
            localStorage.clear();
            window.location.href = "/";
        }else{
            localStorage.clear();
            window.location.href = "/";
        }
    }
    return Promise.reject(error);
});

instance.interceptors.request.use((res) => {
    return res

}, (error) => {

});

export default instance;




