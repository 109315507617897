import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../common/Login";

export default () => {
    return (
        <Switch>
            <Route
                exact
                component={Login}
                path="/"
            />
        </Switch>
    )
}