import React, { createContext, useState, useEffect } from 'react';
import DataService from "./dataService";
import AxiosService from "./AxiosService";

const AuthContext = createContext({})

const AuthProvider = (props) => {
    const [user, setUser] = useState(false);
    const [usertype, setUserType] = useState(null);
    const [token, setToken] = useState(null);


    const dataService = new DataService();
    const axiosService = new AxiosService();
    let axiosInstance;



    /**
     * @purpose Logged in the User via AuthContext and set token and user
     * @param url
     * @param obj
     * @returns {Promise<AxiosResponse<any>>}
     */
    const signin = async (url, obj) => {
        return new Promise((resolve, reject) => {
            axiosService.login(url, obj, (res) => {
                if (res?.data?.token) {
                    localStorage.setItem("token", res.data.token);
                    axiosInstance = axiosService.instance;
                    
                    setToken(res.data.token);
                    setUser(true);
                    
                    
                    window.location.replace('/admin/dashboard');
                    
                    resolve(res)
                } else if (res.error) {
                    reject(res)
                } else {
                    let obj = {};
                    obj["error"] = res.error;
                    reject(res)
                }
            })
        })

    }

    const signup = async (url, obj) => {

    }

    const signout = () => {
        setUser(false);
        axiosService.logout();
    }

    useEffect(() => {
        /**
         * @description event listener to localstorage token item to get the user token and subscribe to accordingly
         */
        const tokenListener = window.addEventListener('storage', function (event) {
            if (event.storageArea === localStorage) {
                // It's local storage
                if (!dataService.getFromLocalStorage('token')) {
                    setUser(false)
                }

            }
        }, false);


        if (dataService.getFromLocalStorage('token')) {
            setUserType(dataService.getFromLocalStorage('user_type'));
            setUser(true)

        } else {
            setUserType(null)
            setUser(false)
        }

        return tokenListener;
    }, [user])


    return (
        <AuthContext.Provider
            value={{ user, usertype, token, signin, signout, signup, axiosInstance }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}


export { AuthContext, AuthProvider }
