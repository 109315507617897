// export const APP_BASE_URL = "https://69fb-117-223-146-68.ngrok.io/api/";
export const APP_BASE_URL = "https://publixpress.site/api/"; 
export const ASSET_PATH = "https://cdn.shopify.com/s/files/1/0649/0439/7023/files/";

export const SEARCH_IMG = "https://cdn.shopify.com/s/files/1/0649/0439/7023/files/search_image.png?v=1654144242"

const SHOPIFY_DOMAIN = "https://publixpress-staging.myshopify.com/";
export const PRODUCT_VIEW = SHOPIFY_DOMAIN + "products/";
export const COLLECTION_VIEW = SHOPIFY_DOMAIN + "collections/";
export const PRODUCT_EDIT = SHOPIFY_DOMAIN + "admin/products/";

export const payment_status = {
  PAID: { label: "Paid", value: "PAID", color: "green" },
  REFUNDED: { label: "Refunded", value: "REFUNDED", color: "orange" },
  PARTIAL_PAID: {
    label: "Partial Paid", 
    value: "PARTIAL_PAID",
    color: "orange",
  },
  PARTIAL_REFUNDED: {
    label: "Partial Refunded",
    value: "PARTIAL_REFUNDED",
    color: "orange",
  },
  ON_HOLD: { label: "OnHold", value: "ON_HOLD", color: "blue" },
  UNPAID: { label: "Unpaid", value: "UNPAID", color: "blueDark" },
  VOIDED: { label: "Void", value: "VOIDED", color: "inkLightest" },
};

export const fulfillmentStatusArr = {
  unfulfilled: {
    label: "Unfulfilled",
    value: "unfulfilled",
    color: "inkLightest",
  },
  fulfilled: { label: "Fulfilled", value: "fulfilled", color: "green" },
  partially: { label: "Partially", value: "partially", color: "orange" },
};

//Order status options
export const OrderStatus = [
  { label: "Pending", value: "pending" },
  { label: "Authorized", value: "authorized" },
  { label: "Partially Paid", value: "partially_paid" },
  { label: "Paid", value: "paid" },
  { label: "Partially Refunded", value: "partially_refunded" },
  { label: "Refunded", value: "refunded" },
  { label: "Voided", value: "voided" },
];

//Order status options
export const ShipmentStatus = [
  { label: "Unmapped", value: "Unmapped" },
  { label: "Out Of Stock", value: "Out Of Stock" },
  { label: "New", value: "New" },
  { label: "Invoiced", value: "Invoiced" },
  { label: "Ready To Ship", value: "Ready To Ship" },
  { label: "Pickup Scheduled", value: "Pickup Scheduled" },
  { label: "Pickup Queued", value: "Pickup Queued" },
  { label: "Pickup Error", value: "Pickup Error" },
  { label: "Shipped", value: "Shipped" },
  { label: "Delivered", value: "Delivered" },
];

//date picker options
export const datePickerOptions = [
  { label: "Custom", value: "custom" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last7day" },
  { label: "Last 30 days", value: "last30days" },
  { label: "Last 90 days", value: "last90days" },
  { label: "Last month", value: "lastmonth" },
  { label: "Last year", value: "lastyear" },
  { label: "Week to date", value: "weektodate" },
  { label: "Month to date", value: "monthtodate" },
  { label: "Year to date", value: "yeartodate" },
];

//default page options
export const defaultPageOptions = [
  { label: "10 entries", value: "10" },
  { label: "25 entries", value: "25" },
  { label: "50 entries", value: "50" },
  { label: "100 entries", value: "100" },
];

//sorting options
export const sortOptions = [
  { label: "A-Z", value: "atoz" },
  { label: "Z-A", value: "ztoa" },
  { label: "Created (Oldfirst)", value: "createold" },
  { label: "Created (Newfirst)", value: "createnew" },
  { label: "Updated (Oldfirst)", value: "updateold" },
  { label: "Updated (Newfirst)", value: "updatenew" },
];

//variant options
export const varientOptions = [
  { label: "Bag Capacity", value: "Bag Capacity"},
  { label: "Bee Year", value: "Bee Year"},
  { label: "Brand Size", value: "Brand Size"},
  { label: "Cable Length", value: "Cable Length"},
  { label: "Capacity", value: "Capacity"},
  { label: "Chest/Bust", value: "Chest-Bust"},
  { label: "Color", value: "Color"},
  { label: "Connectivity", value: "Connectivity"},
  { label: "Designed For", value: "Designed For"},
  { label: "Dimension", value: "Dimension"},
  { label: "Display Size", value: "Display Size"},
  { label: "Frame Color", value: "Frame Color"},
  { label: "Laptop Size", value: "Laptop Size"},
  { label: "Launch Year", value: "Launch Year"},
  { label: "Length", value: "Length"},
  { label: "Material", value: "Material"},
  { label: "Mirror Included", value: "Mirror Included"},
  { label: "Number Of Shelves", value: "Number Of Shelves"},
  { label: "Pack of", value: "Pack of"},
  { label: "Power Consumption", value: "Power Consumption"},
  { label: "Power Output", value: "Power Output"},
  { label: "Quantity", value: "Quantity"},
  { label: "Ram", value: "Ram"},
  { label: "Set Up", value: "Set Up"},
  { label: "Shade", value: "Shade"},
  { label: "Shoulder", value: "Shoulder"},
  { label: "Size", value: "Size"},
  { label: "Sleeve Length", value: "Sleeve Length"},
  { label: "Storage", value: "Storage"},
  { label: "Storage Included", value: "Storage Included"},
  { label: "Straps", value: "Straps"},
  { label: "Style", value: "Style"},
  { label: "Thickness", value: "Thickness"},
  { label: "Total Jars", value: "Total Jars"},
  { label: "Variant", value: "Variant"},
  { label: "Waist Size", value: "Waist Size"},
];

//weight options 
export const weightOptions = [
  {label:"kg",value:"kg"},
  {label:"lb",value:"lb"},
  {label:"oz",value:"oz"},
  {label:"g",value:"g"}
]

//Pagination Object
export const _pagination = {
  hasNext: false,
  hasPrevious: false,
  page: 1,
  perpage: 10,
  showing:null
}

//default pagination showing data
export const showing = "25";

//product status options
export const ProductStatusOptions = [
  { label: "All", value: "All" },
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "In-Review" },
];

//KAssured Product
export const AISLE = [
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },

  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },

  { label: "31", value: "31" },
  { label: "32", value: "32" },
  { label: "33", value: "33" },
  { label: "34", value: "34" },
  { label: "35", value: "35" },
  { label: "36", value: "36" },
  { label: "37", value: "37" },
  { label: "38", value: "38" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },

  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
  { label: "45", value: "45" },
  { label: "46", value: "46" },
  { label: "47", value: "47" },
  { label: "48", value: "48" },
  { label: "49", value: "49" },
  { label: "50", value: "50" },

  { label: "51", value: "51" },
  { label: "52", value: "52" },
  { label: "53", value: "53" },
  { label: "54", value: "54" },
  { label: "55", value: "55" },
  { label: "56", value: "56" },
  { label: "57", value: "57" },
  { label: "58", value: "58" },
  { label: "59", value: "59" },
  { label: "60", value: "60" },

  { label: "61", value: "61" },
  { label: "62", value: "62" },
  { label: "63", value: "63" },
  { label: "64", value: "64" },
  { label: "65", value: "65" },
  { label: "66", value: "66" },
  { label: "67", value: "67" },
  { label: "68", value: "68" },
  { label: "69", value: "69" },
  { label: "70", value: "70" },

  { label: "71", value: "71" },
  { label: "72", value: "72" },
  { label: "73", value: "73" },
  { label: "74", value: "74" },
  { label: "75", value: "75" },
  { label: "76", value: "76" },
  { label: "77", value: "77" },
  { label: "78", value: "78" },
  { label: "79", value: "79" },
  { label: "80", value: "80" },

  { label: "81", value: "81" },
  { label: "82", value: "82" },
  { label: "83", value: "83" },
  { label: "84", value: "84" },
  { label: "85", value: "85" },
  { label: "86", value: "86" },
  { label: "87", value: "87" },
  { label: "88", value: "88" },
  { label: "89", value: "89" },
  { label: "90", value: "90" },

  { label: "91", value: "91" },
  { label: "92", value: "92" },
  { label: "93", value: "93" },
  { label: "94", value: "94" },
  { label: "95", value: "95" },
  { label: "96", value: "96" },
  { label: "97", value: "97" },
  { label: "98", value: "98" },
  { label: "99", value: "99" },
];

export const SHELF = [
  { label: "00", value: "00" },
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
];

export const BOX = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
];

export const rolePermission = [
  { label: "Dashboard", value: "Dashboard" },
  { label: "Manage Orders", value: "Manage Orders" },
  { label: "Manage Category", value: "Manage Category" },
  { label: "Manage Products", value: "Manage Products" },
  { label: "Customers", value: "Customers" },
];

export const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
