import React, { useEffect } from "react";
import { DataTable, SkeletonBodyText } from "@shopify/polaris";
import { ASSET_PATH } from "../../../appconfig/Settings";

const tableRows = [];

export default function SkeletonManaheOrder() {

  useEffect(() => {
    getMyProductListSkeleton();
  }, []);

  const getMyProductListSkeleton = () => {
    tableRows.length = 0;
    //loop iterate
    for (let i = 0; i < 10; i++) {
      const tableRowsAppend = [];
      tableRowsAppend.push(
        <img src={ASSET_PATH + "no-image.jpg?v=1654230497"} alt="No-Display"></img>
      );
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      //Table Row append in array
      tableRows.push(tableRowsAppend);
    }
  };
  return (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={["Image", "Category Name", "Number of Products", "Actions"]}
        rows={tableRows}
      />
    </>
  );
}
