import React, { useCallback, useState, useContext, useEffect } from "react";
import { Frame, TopBar, Icon, FooterHelp } from "@shopify/polaris";
import { useHistory, useLocation } from "react-router-dom";
import {
  LogOutMinor,
  HomeMajor,
  ProductsMajor,
  OrdersMajor,
  CustomersMajor,
  CategoriesMajor,
  ReportsMajor,
  ProfileMajor,
  EditMinor,
  NotificationMajor,
} from "@shopify/polaris-icons";
import "../scss/submenu.scss";
import axiosInstance from "../../services/axios.service";
import { AuthContext } from "../../services/AuthProvider";
import { NAV_COUNT } from "../../apis/ApiConstant";

//End Count Function
const FrameExample = ({ children }) => {
  useEffect(() => {
    getDataFromAPI();
  }, []);

  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getDataFromAPI();
  }, [location.pathname]);


  const auth = useContext(AuthContext);

  let storeAdminUrl =
    "https://" + localStorage.getItem("shop_domain") + "/admin";

  function toggleClick() {
    auth.signout();
  }

  // const [roles,setRoles] = useState(localStorage.getItem('roles').split(','));
  const [userMenuActive, setUserMenuActive] = useState(false);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  function handleChangePassword(){
    history.push("/admin/changepassword");
  }

  const userMenuActions = [
    {
      items: [ 
              { content: "Change Password", icon: EditMinor, onAction: handleChangePassword },
              { content: "Logout", icon: LogOutMinor, onAction: toggleClick }
        ],
    },
  ];

  const [navCount,setNavCount] = useState();

  //get nav count
  const getDataFromAPI = () => {
    axiosInstance.get(NAV_COUNT).then((res) => {
      setNavCount(res.data.data);
    });
  };

  const userMenuMarkup = (
    <>
      <TopBar.UserMenu
        actions={userMenuActions}
        open={userMenuActive}
        onToggle={toggleUserMenuActive}
      />
      <span style={{ "marginRight": "10px" }}>{localStorage.getItem('name')}</span>
    </>
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const nav_arr = [
    {
      title: "Dashboard",
      link: "/admin/dashboard",
      icon: HomeMajor,
    },
    {
      title: "Manage Orders",
      link: "/admin/manageOrders",
      badge: <>{!window.cn(navCount) ? navCount.totalOrders : 0 }</>,
      icon: OrdersMajor,
    },
    {
      title: "Manage Category",
      link: "/admin/manageCategory",
      icon: CategoriesMajor,
    },
    {
      title: "Manage Products",
      link: "/admin/products",
      icon: ProductsMajor,
      subLink: [
        {
          title: "Products",
          link: "/admin/products",
          badge: <>{!window.cn(navCount) ? navCount.totalApprovedProducts : 0 }</>,
        },
        {
          title: "Add Product",
          link: "/admin/addProduct",
        },
        {
          title: "Import List",
          link: "/admin/importProduct",
          id: "importProductCount",
          badge: <>{!window.cn(navCount) ? navCount.totalImportProducts : 0 }</>,
        },
      ],
    },
    {
      title: "Customers",
      link: "/admin/customers",
      icon: ProfileMajor,
    },
    {
      title: "Subscribers",
      link: "/admin/subscriber",
      icon: NotificationMajor,
    },
  ];
  let custom = [];
  let custom_sub = [];

  function redirectPage(url) {
    if (url === "next-page") {
      window.open(storeAdminUrl);
      if (window.innerWidth < 769) {
        document.querySelector(".Polaris-Frame__NavigationDismiss").click();
      }
    } else {
      history.replace(url);
      if (window.innerWidth < 769) {
        document.querySelector(".Polaris-Frame__NavigationDismiss").click();
      }
    }
  }

  const [subMenu, setSubMenu] = useState(false);

  function subMenuFun(e) {
    let elems = document.querySelector(".active_main");
    if (!subMenu) {
      setSubMenu(true);
      if (window.innerWidth > 769) {
        document.getElementsByClassName("sub_inner_div")[0].click();
      }
      if (elems != null) {
        elems.classList.remove("active_main");
      }
    }
  }

  function menuFun(e) {
    setSubMenu(false);
    let elems = document.querySelector(".active_main");
    let sub_elems = document.querySelector(".active_sub_child");
    if (elems != null) {
      elems.classList.remove("active_main");
    }
    e.currentTarget.classList.add("active_main");
    if (sub_elems != null) {
      sub_elems.classList.remove("active_sub_child");
    }
  }

  function subChildMenuFun(e) {
    let elems = document.querySelector(".active_sub_child");
    if (elems != null) {
      elems.classList.remove("active_sub_child");
    }
    e.currentTarget.classList.add("active_sub_child");
  }

  function submenuParent(url) {
    let elems = document.querySelector(".active_sub_child");
    if (window.innerWidth < 769) {
      if (!subMenu) {
        setSubMenu(true);
      } else {
        setSubMenu(false);
        elems.classList.remove("active_sub_child");
      }
    } else {
      redirectPage(url);
    }
  }

  for (let i = 0; i < nav_arr.length; i++) {
    // if(roles.includes(nav_arr[i].title)){
    if (nav_arr[i].subLink) {
      let class_submenu = subMenu ? "active_child" : "";
      custom.push(
        <li
          key={i}
          className={`navbar_li ${class_submenu}`}
          onClick={subMenuFun}
        >
          <div className="navbar_div">
            <div
              className="navbar_inner flex_view_xs"
              onClick={() => submenuParent(nav_arr[i].link)}
            >
              <Icon source={nav_arr[i].icon} />
              <span className="navbar_text">{nav_arr[i].title}</span>
            </div>
          </div>
          <div className="sub_div">
            <ul key={"sub" + i} className="sub_ul">
              {custom_sub}
            </ul>
          </div>
        </li>
      );

      nav_arr[i].subLink.map((sublink, index) => {
        custom_sub.push(
          <li key={"cs" + index}>
            <div
              className="sub_inner_div"
              onClick={subChildMenuFun}
              url-id={sublink.link}
            >
              <span
                onClick={() => redirectPage(sublink.link)}
                className="sub_btn"
              >
                <span className="sub_title">{sublink.title}</span>
                {sublink.badge !== "" && sublink.badge !== undefined ? (
                  <div className="Polaris-Navigation__Badge">
                    <span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">
                      <span className="Polaris-Badge__Content" id={sublink.id}>
                        {sublink.badge}
                      </span>
                    </span>
                  </div>
                ) : null}
              </span>
            </div>
          </li>
        );
      });
    } else {
      custom.push(
        <li
          key={i}
          className={"navbar_li " + (i === 0 ? "active_main" : "")}
          onClick={menuFun}
          url-id={nav_arr[i].link}
        >
          <div className="navbar_div">
            <div
              className="navbar_inner flex_view_xs"
              onClick={() => redirectPage(nav_arr[i].link)}
            >
              <Icon source={nav_arr[i].icon} />
              <span className="navbar_text">{nav_arr[i].title}</span>
              {nav_arr[i].badge !== "" && nav_arr[i].badge !== undefined ? (
                <div className="Polaris-Navigation__Badge">
                  <span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">
                    <span className="Polaris-Badge__Content" id={nav_arr[i].id}>
                      {nav_arr[i].badge}
                    </span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>

        </li>
      );
    }
    // }
  }
  const nav_custom = <ul className="navbar_ul_main">{custom}</ul>;

  window.onload = function () {
    redirectPage(history.location.pathname);
    let nav_bar_li = document.getElementsByClassName("navbar_li");
    let nav_bar_child = document.getElementsByClassName("sub_inner_div");
    for (let i = 0; i < nav_bar_li.length; i++) {
      let ele = nav_bar_li[i].getAttribute("url-id");
      if (history.location.pathname == ele) {
        nav_bar_li[i].click();
        if (window.innerWidth < 769) {
          document.querySelector(".Polaris-Frame__NavigationDismiss").click();
        }
      }
    }
    for (let i = 0; i < nav_bar_child.length; i++) {
      let ele = nav_bar_child[i].getAttribute("url-id");
      if (history.location.pathname == ele) {
        nav_bar_li[1].click();
        nav_bar_child[i].click();
        if (window.innerWidth < 769) {
          document.querySelector(".Polaris-Frame__NavigationDismiss").click();
        }
      } else if (
        history.location.pathname.match("/productlist/") ||
        history.location.pathname.match("/product/")
      ) {
        nav_bar_child[0].click();
        if (window.innerWidth < 769) {
          document.querySelector(".Polaris-Frame__NavigationDismiss").click();
        }
      } else if (history.location.pathname.match("/editimportproduct/")) {
        nav_bar_child[1].click();
        if (window.innerWidth < 769) {
          document.querySelector(".Polaris-Frame__NavigationDismiss").click();
        }
      } else if (history.location.pathname.match("/editproduct/")) {
        nav_bar_child[2].click();
        if (window.innerWidth < 769) {
          document.querySelector(".Polaris-Frame__NavigationDismiss").click();
        }
      }
    }
  };

  return (
    <div>
      <Frame
        topBar={topBarMarkup}
        navigation={nav_custom}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        <React.Fragment>{children}</React.Fragment>
        <FooterHelp>
          <div><span className="black-txt">Designed &amp; Developed By&nbsp;</span><a href="https://helicoreinfo.com" style={{ "textDecoration": "none", "color": "black" }} target="_blank"><img src="https://gujaratpharmacycouncil.co.in/front/favicon-helicore.png" width="32" height="32" /><span className="black-txt">&nbsp;Helicore Info Pvt. Ltd.</span></a></div>
        </FooterHelp>
      </Frame>
    </div>
  );
};
export default FrameExample;
