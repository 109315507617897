/**
 * @purpose used for accessing localstorage service
 */
export default class DataService {
    constructor() {
    }

    setToLocalStorage(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    getFromLocalStorage(key) {
        const item = localStorage.getItem(key)
        return (item && item != 'null') ? item : null
    }

    setMultipleItems(items) {
        items.map((item, index) => {
            this.setToLocalStorage(item.key, item.value)
        })
    }

    clearFromLocalStorage(key) {
        localStorage.removeItem(key)
    }

    clearAll() {
        localStorage.clear()
    }
}
