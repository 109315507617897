import { Spinner } from '@shopify/polaris';
import React, { useContext } from 'react';
import {BrowserRouter} from "react-router-dom";
import { AuthContext } from '../services/AuthProvider';
import Admin from "./Admin";
import AuthPages from './Authpages';

export default () => {
    const auth = useContext(AuthContext);
    const user = auth.user;

    return(
        <BrowserRouter>
            {user == null && <Spinner accessibilityLabel="Spinner example" size="large" />}
            {user == false && <AuthPages />}
            {user == true && <Admin />}
        </BrowserRouter>
    )
}
