import React, { useEffect } from "react";
import { DataTable, SkeletonBodyText } from "@shopify/polaris";

const tableRows = [];

export default function SkeletonCustomerList() {
  useEffect(() => {
    getMyProductListSkeleton();
  }, []);

  const getMyProductListSkeleton = () => {
    tableRows.length = 0;
    //loop iterate
    for (let i = 0; i < 10; i++) {
      const tableRowsAppend = [];
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      tableRowsAppend.push(<SkeletonBodyText />);
      //Table Row append in array
      tableRows.push(tableRowsAppend);
    }
  };
  return (
    <>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          "Sr No.",
          "Full Name",
          "Email",
          "Mobile No.",
          "Shopify Customer ID",
          "Order Count",
          "Total Spent",
          "Created At",
        ]}
        rows={tableRows}
      />
    </>
  );
}
