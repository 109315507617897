import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    FormLayout,
    Layout,
    Page,
    TextField,
    Stack,
    Button,
    Checkbox,
    Icon,
    Select,
    TextStyle,
} from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import * as ReactBootStrap from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AddImage from "../components/AddImage";
import FeaturedImg from "../components/FeaturedImg";
import SelectVariants from "../components/SelectVarients";
import Editor, { modules, formats } from "../components/TextEditor";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../scss/product.scss";
import Selectreact from 'react-select'
import { ADD_PRODUCT, LIST_CATEGORY } from "../../apis/ApiConstant";
import axiosInstance from "../../services/axios.service";
import ToasterToggle from "../components/Toster";
import AuthorEditor, { authorModules, authorFormats } from "../components/TextEditorAuthor";
import UploadFile from "../components/UploadFile";

const blackListedVariantGlobal = [];

export default function AddProduct() {
    document.title = "Add Product";
    const [addProd, setaddProd] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [varientData, setVarientData] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [toastMessage, setToastMessage] = useState();
    const [toasttoggle, setToastToggle] = useState(false);
    const [isError, setisError] = useState(false);
    const history = useHistory();


    const Typeoptions = [
        { label: "Ebook", value: "Ebook" },
        { label: "Paper Book", value: "Paper Book" },
        { label: "Ebook/Paper Book", value: "Ebook/Paper Book" },
    ];


    //call api on page load for collection list
    useEffect(() => {
        getCollectionFromAPI();
    }, []);

    //get collection list
    const getCollectionFromAPI = () => {
        const collections = [];
        axiosInstance.get(LIST_CATEGORY + "?per_page=500" + "&page=" + "&search=" + "&sort=atoz").then((res) => {
            const collection_data = res.data.data;
            for (let i = 0; i < collection_data.length; i++) {
                const collectionObj = {};
                collectionObj["label"] = collection_data[i].title;
                collectionObj["value"] = collection_data[i].shopify_collection_id;
                collections.push(collectionObj);
            }
            setCollectionData(collections);
        });
    };

    //check field validation
    const [validation, setValidation] = useState({
        product_title: false,
        author_name: false,
        publishing_date: false,
        product_type: false,
        featured_img: false,
        variant: false,
        paperBuck: false,
        otherSite: false
    });

    // handle onChange event of the dropdown
    const handleCollectionChange = (e) => {
        setSelectedCollection(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    //makes object for add product data
    const onHandleChange = (name, value) => {
        if (addProd[name] !== "" || addProd[name] !== undefined) {
            setValidation({ ...validation, [name]: false });
        }
        setaddProd({ ...addProd, [name]: value });
    };

    //main usestate object
    const [vary, setVary] = useState({
        blackListedVariant: [],
        //stores new combos
        comboVariant: [],
        //checks if there is no more than 3 option available
        allVarientPresentAtDom: false,
        //variant options array (max 1)
        varients: [
            {
                id: 1,
                textFieldValue: "",
                optionValue: [],
                isAdded: true,
                isDisabled: false,
                checkValidation: false
            }
        ],
        //adds another option for variant
        addVarientToDom: () => {
            if (!vary.allVarientPresentAtDom) {
                const tempVarients = vary.varients;
                let isNewAdded = false;
                tempVarients.forEach(item => {
                    if (!isNewAdded && !item.isAdded) {
                        item.isAdded = true;
                        isNewAdded = true;
                    }
                })
                let tempAllVarientPresentAtDom = false;
                if (tempVarients.filter(item => item.isAdded == true).length > 2) {
                    tempAllVarientPresentAtDom = true
                }
                setVary((prev) => ({
                    ...prev,
                    varients: tempVarients, allVarientPresentAtDom: tempAllVarientPresentAtDom
                }));
            }
        },
        //removes option section from variant
        removeVarientFromDom: (id) => {
            const tempVarients = vary.varients;

            tempVarients.forEach(item => {
                if (item.id == id) {
                    vary.deleteOptionValueChange(item.id, item.optionValue);
                    item.isAdded = true;
                    item.optionValue = [];
                    setIsChecked(false)


                }
            })

            // if (!tempVarients.find(item => item.isAdded == true)) {
            //     setIsChecked(false)
            //     vary.addVarientToDom();
            // }

            setVary((prev) => ({ ...prev, varients: tempVarients, allVarientPresentAtDom: false }))
            vary.updateComboVariant()
            setVary((prev) => ({ ...prev, comboVariant: [] }))
        },
        //deletes one value from option
        deleteOptionValueChange: (id, value) => {

            const tempVarients = vary.varients;
            tempVarients.forEach(item => {
                if (item.id == id) {
                    const index = item.optionValue.indexOf(value);
                    if (index > -1) {
                        item.optionValue.splice(index, 1);
                    }
                }
            });

            if (tempVarients[0].optionValue.length == 0) {
                setVary({ ...vary, comboVariant: [] })
            }
            setVary((prev) => ({ ...prev, varients: tempVarients }));
            vary.updateComboVariant();
        },
        //handle option values on change
        handleOptionValueChange: (id, name, newValue, isSubmit) => {
            if (newValue.trim().length !== 0) {
                const tempVarients = vary.varients;
                tempVarients.forEach(item => {
                    if (item.id == id) {
                        item.textFieldValue = newValue;
                        if (isSubmit && vary.checkValue(item.optionValue, newValue)) {
                            item.optionValue = [...item.optionValue, newValue]
                            item.textFieldValue = "";
                        }
                        if (!vary.checkValue(item.optionValue, newValue)) {
                            item.textFieldValue = "";
                        }
                    }
                });
                setVary((prev) => ({ ...prev, varients: tempVarients }))
                vary.updateComboVariant();
            }
        },
        //checks if the vale is already present or not
        checkValue: (arr, value) => {
            let ar = arr.filter(item => item.toLowerCase() == value.toLowerCase())
            return ar.length <= 0;
        },
        updateTextFiled: (id, value) => {
            const tempVarients = vary.varients;
            tempVarients.forEach(item => {
                if (item.id == id) {
                    item.textFieldValue = value
                }
            });
            setVary((prev) => ({ ...prev, varients: tempVarients }))
        },

        //makes variant
        updateComboVariant: () => {
            let finalArray = [];
            vary.varients.map(item => {
                if (item.optionValue.length > 0) {
                    finalArray.push([...item.optionValue])
                }
            })

            if (finalArray.length > 0) {
                // Create matrix of (Different Combination of Variant Options
                let parts = finalArray,
                    result = parts.reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w)
                    )), []));

                let newVarient = [];
                result.map((item, itemIndex) => {
                    const temp = {
                        varient_title: '',
                        price: '',
                        sku: '',
                        compared_price: '',
                        variant_file: '',
                        IsDeleted: false,
                    };
                    finalArray.map((t, index) => {
                        if (finalArray.length === 1) {
                            temp.varient_title = item.toString();
                        }
                        else {
                            const tempTitle = item.join("/");
                            temp.varient_title = tempTitle;
                        }
                    });
                    const isBlackListed = blackListedVariantGlobal.find(item => item === temp.varient_title)
                    if (!isBlackListed) {
                        newVarient.push(temp);
                    }

                })
                setVary((prev) => ({ ...prev, comboVariant: newVarient }))
            }
        },
    })


    //main usestate object of Paper Buck
    const [paperBuckObject, setPaperBuckObject] = useState({
        blackListedPaperBuck: [],
        //stores new combos
        comboPaperBuck: [],

        allPaperBuckPresentAtDom: false,
        //paperBuck options array (max 1)
        paperBuck: [{
            id: 1,
            textFieldValue: "",
            urlValue: "",
            paperbackPrice:"",
            isAdded: true,
            isDisabled: false,
            checkValidation: false
        }],

        //adds another option for paperBuck
        addPaperBuckToDom: () => {
            paperBuckObject.paperBuck.push({
                id: paperBuckObject.paperBuck[paperBuckObject.paperBuck.length - 1].id + 1,
                textFieldValue: "",
                urlValue: "",
                paperbackPrice : "",
                isAdded: true,
                isDisabled: false,
                checkValidation: false
            });

            const tempPaperBuck = paperBuckObject.paperBuck;
            let isNewAdded = false;
            tempPaperBuck.forEach(item => {
                if (!isNewAdded && !item.isAdded) {
                    item.isAdded = true;
                    isNewAdded = true;
                }
            })
            let tempAllPaperBuckPresentAtDom = false;

            if (tempPaperBuck.filter(item => item.isAdded == true).length > 2) {
                tempAllPaperBuckPresentAtDom = true
            }
            setPaperBuckObject((prev) => ({
                ...prev,
                paperBuck: tempPaperBuck, allPaperBuckPresentAtDom: tempAllPaperBuckPresentAtDom
            }));
        },


        //removes option section from variant
        removePaperBuckFromDom: (id) => {
            const tempPaperBuck = paperBuckObject.paperBuck;

            tempPaperBuck.forEach(item => {
                if (item.id == id) {
                    item.isAdded = false;
                }
            })
            if (!tempPaperBuck.find(item => item.isAdded == true)) {
                setIsChecked(false)
                paperBuckObject.addPaperBuckToDom();
            }

            setPaperBuckObject((prev) => ({ ...prev, paperBuck: tempPaperBuck, allVarientPresentAtDom: false }))

        },

        onHandleChange: (name, value) => {
            const tempPaperBuck = paperBuckObject.paperBuck;
            if (paperBuckObject.paperBuck[name] !== "" || paperBuckObject.paperBuck[name] !== undefined) {
                setValidation({ ...validation, [name]: false })
            }
            setPaperBuckObject((prev) => ({ ...prev, paperBuck: tempPaperBuck, [name]: value, allVarientPresentAtDom: false }));
        },

        //checks if the vale is already present or not
        checkValue: (arr, value) => {
            let ar = arr.filter(item => item.toLowerCase() == value.toLowerCase())
            return ar.length <= 0;
        },

        updateTextFieldTitleValue: (id, value) => {
            const tempPaperBuck = paperBuckObject.paperBuck
            tempPaperBuck.forEach(item => {
                if (item.id == id) {
                    item.textFieldValue = value
                }
            });
            setPaperBuckObject((prev) => ({ ...prev, paperBuck: tempPaperBuck, allVarientPresentAtDom: false }))
        },

        updateTextFieldUrlPriceValue: (id, value) => {
            const tempPaperBuck = paperBuckObject.paperBuck
            console.log(id, value)
            tempPaperBuck.forEach(item => {
                if (item.id == id) {
                    item.paperbackPrice = value
                }
            });
            setPaperBuckObject((prev) => ({ ...prev, paperBuck: tempPaperBuck, allVarientPresentAtDom: false }))
        },

        updateTextFieldLinkValue: (name, id, value) => {
            if (paperBuckObject.urlPatternValidation(paperBuckObject.paperBuck[name])) {
                setValidation({ ...validation, [name]: false });
            }
            const tempPaperBuck = paperBuckObject.paperBuck
            tempPaperBuck.forEach(item => {
                if (item.id == id) {
                    item.urlValue = value
                }
            });
            setPaperBuckObject((prev) => ({ ...prev, paperBuck: tempPaperBuck, allVarientPresentAtDom: false }))
        },

        urlPatternValidation: (URL) => {
            const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
            return regex.test(URL);
        }
    })

    //main usestate object of Other Site Url
    const [otherSiteObject, setOtherSiteObject] = useState({
        blackListedOtherSite: [],
        //stores new combos
        comboOtherSite: [],

        allOtherSitePresentAtDom: false,
        //paperBuck options array (max 1)
        otherSite: [{
            id: 1,
            title: "",
            url: "",
            price:"",
            isAdded: true,
            isDisabled: false,
            checkValidation: false
        }],

        //adds another option for paperBuck
        addOtherSiteToDom: () => {
            otherSiteObject.otherSite.push({
                id: otherSiteObject.otherSite[otherSiteObject.otherSite.length - 1].id + 1,
                title: "",
                url: "",
                price : "",
                isAdded: true,
                isDisabled: false,
                checkValidation: false
            });

            const tempOtherSite = otherSiteObject.otherSite;
            let isNewAdded = false;
            tempOtherSite.forEach(item => {
                if (!isNewAdded && !item.isAdded) {
                    item.isAdded = true;
                    isNewAdded = true;
                }
            })
            let tempAllOtherSitePresentAtDom = false;

            if (tempOtherSite.filter(item => item.isAdded == true).length > 2) {
                tempAllOtherSitePresentAtDom = true
            }
            setOtherSiteObject((prev) => ({
                ...prev,
                otherSite: tempOtherSite, allOtherSitePresentAtDom: tempAllOtherSitePresentAtDom
            }));
        },


        //removes option section from variant
        removeOtherSiteFromDom: (id) => {
            const tempOtherSite = otherSiteObject.otherSite;

            tempOtherSite.forEach(item => {
                if (item.id == id) {
                    item.isAdded = false;
                }
            })
            if (!tempOtherSite.find(item => item.isAdded == true)) {
                setIsChecked(false)
                otherSiteObject.addOtherSiteToDom();
            }

            setOtherSiteObject((prev) => ({ ...prev, otherSite: tempOtherSite, allVarientPresentAtDom: false }))

        },

        onHandleChangeOtherSite: (name, value) => {
            const tempOtherSite = otherSiteObject.otherSite;
            if (otherSiteObject.otherSite[name] !== "" || otherSiteObject.otherSite[name] !== undefined) {
                setValidation({ ...validation, [name]: false })
            }
            setOtherSiteObject((prev) => ({ ...prev, otherSite: tempOtherSite, [name]: value, allVarientPresentAtDom: false }));
        },

        //checks if the vale is already present or not
        checkValue: (arr, value) => {
            let ar = arr.filter(item => item.toLowerCase() == value.toLowerCase())
            return ar.length <= 0;
        },

        updateTextFieldTitleValue: (id, value) => {
            const tempOtherSite = otherSiteObject.otherSite
            tempOtherSite.forEach(item => {
                if (item.id == id) {
                    item.title = value
                }
            });
            setOtherSiteObject((prev) => ({ ...prev, otherSite: tempOtherSite, allVarientPresentAtDom: false }))
        },

        updateTextFieldUrlPriceValue: (id, value) => {
            const tempOtherSite = otherSiteObject.otherSite
            console.log(id, value)
            tempOtherSite.forEach(item => {
                if (item.id == id) {
                    item.price = value
                }
            });
            setOtherSiteObject((prev) => ({ ...prev, otherSite: tempOtherSite, allVarientPresentAtDom: false }))
        },

        updateTextFieldLinkValue: (name, id, value) => {
            if (otherSiteObject.urlPatternValidation(otherSiteObject.otherSite[name])) {
                setValidation({ ...validation, [name]: false });
            }
            const tempOtherSite = otherSiteObject.otherSite
            tempOtherSite.forEach(item => {
                if (item.id == id) {
                    item.url = value
                }
            });
            setOtherSiteObject((prev) => ({ ...prev, otherSite: tempOtherSite, allVarientPresentAtDom: false }))
        },

        urlPatternValidation: (URL) => {
            const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
            return regex.test(URL);
        }
    })

    //puts value in combos text fields
    const onVarientDataChange = (name, value, id) => {
        if (vary.comboVariant[name] !== "" || vary.comboVariant[name] !== undefined) {
            setValidation({ ...validation, [name]: false });
        }
        setVarientData({ ...varientData, [name]: value });
        let tempVarientArray = vary.comboVariant;
        tempVarientArray.forEach(item => {
            if (item.varient_title == id) {
                item[name] = value;
            }
        })
        setVary((prev) => ({ ...prev, comboVariant: tempVarientArray }))
    }

    //images variables
    let getImagesRefFromAddImage = null;
    let getImagesRefFromFeaturedImage = null;
    let getFilesRefFromFiles = [];

    //gets media images
    const getImagesFromAddImage = (ref) => {
        getImagesRefFromAddImage = ref;
    }

    //gets featured images
    const getImagesFromFeaturedImage = (ref) => {
        getImagesRefFromFeaturedImage = ref;
    }

    let fileArray = [];

    const getFilesFromFiles = (event) => {
        fileArray.push(event.getFiles());
        getFilesRefFromFiles = event;
    }
    //checks validation
    const validate = () => {
        let validate = true;
        const featuredImage = getImagesRefFromFeaturedImage?.getImages();

        let variant_file = null;

        if (getFilesRefFromFiles != null && getFilesRefFromFiles.length != 0) {
            variant_file = getFilesRefFromFiles?.getFiles();
        }

        //Validation
        if (window.cn(addProd.product_title)) {
            setValidation((prev) => ({ ...prev, product_title: true }))
            validate = false;
        } if (window.cn(addProd.author_name)) {
            setValidation((prev) => ({ ...prev, author_name: true }))
            validate = false;
        } if (window.cn(addProd.product_type)) {
            setValidation((prev) => ({ ...prev, product_type: true }))
            validate = false;
        } if (window.cn(addProd.publishing_date)) {
            setValidation((prev) => ({ ...prev, publishing_date: true }))
            validate = false;
        } if (featuredImage.length == 0) {
            setValidation((prev) => ({ ...prev, featured_img: true }))
            validate = false;
        } else {
            setValidation((prev) => ({ ...prev, featured_img: false }))
        }
        if (vary.comboVariant.length > 0) {
            vary.comboVariant.map((item) => {
                if (item.price === '' ||
                    item.sku === '' ||
                    variant_file.length == 0) {
                    setValidation((prev) => ({ ...prev, variant: true }))
                    validate = false;
                } else {
                    setValidation((prev) => ({ ...prev, variant: false }))
                }
            });
        }


        // const allVariants = vary.varients;



        if (!validate) {
            window.scrollTo(200, 0);
        }

        return validate;
    }

    //On Submit Click
    const onProductSubmit = async () => {
        // Get Images from the Add Image Component
        const images = getImagesRefFromAddImage?.getImages();

        // Get Images from the Featured Image Component
        const featured_img = getImagesRefFromFeaturedImage?.getImages();

        //formdata object
        const formData = new FormData();

        //is validated or not
        const isValidate = validate();

        if (isValidate) {
            setLoading(true);

            const finalObj = addProd;

            vary.varients.forEach(item => {
                if (item.id == 1) {
                    finalObj["option1_val"] = item.optionValue.toString();
                }
            });

            Object.keys(finalObj).forEach((item) => {
                if (item !== "option1_val") {
                    formData.append(item, finalObj[item]);
                }
            });

            //remove deleted Variant from combo vaiant
            let finalComboVariant = vary.comboVariant.filter(item => !item.IsDeleted);
            let variantUrlData = [];
            // vary.comboVariant.forEach((item, index) => {
            let fileCount = 0;
            fileArray.forEach((item, index) => {
                // if(item != [] && item != null && item != ""){
                if(!window.cn(item[0]) && item[0] != undefined){
                    formData.append("varient_url[" + fileCount + "]", item[0]);
                    fileCount += 1;
                }
                // }
            });

            for (let x = 0; x < finalComboVariant.length; x++) {
                variantUrlData[x] = finalComboVariant[x].variant_file;
            }

            //remove IsDeleted key
            for (let x = 0; x < finalComboVariant.length; x++) {
                delete finalComboVariant[x]["IsDeleted"];
                delete finalComboVariant[x]["variant_file"];
            }

            //send json stringify variants data in formdata
            formData.append("product_varient", JSON.stringify(finalComboVariant));


            for (let x = 0; x < finalComboVariant.length; x++) {
                finalComboVariant[x]["variant_file"] = variantUrlData[x];
            }



            let FinalPaperBuckArray = [];
            if (paperBuckObject.paperBuck != null && paperBuckObject.paperBuck != "" && paperBuckObject.paperBuck.length > 0) {


                //Set Paperbuck Data in Json
                const finalPaperBuck = paperBuckObject.paperBuck.filter(item => !item.IsDeleted);

                //remove IsDeleted key
                for (let x = 0; x < finalPaperBuck.length; x++) {
                    delete finalPaperBuck[x]["IsDeleted"];
                }

                finalPaperBuck.forEach(item => {
                    if (item.textFieldValue != null && item.textFieldValue != "" &&
                        item.urlValue != null && item.urlValue != "") {

                        const paperBuckObject = {};
                        paperBuckObject["id"] = item.id;
                        paperBuckObject["textFieldValue"] = item.textFieldValue;
                        paperBuckObject["urlValue"] = item.urlValue;
                        paperBuckObject["paperbackPrice"] = item.paperbackPrice;
                        paperBuckObject["isAdded"] = item.isAdded;
                        paperBuckObject["isDisabled"] = item.isDisabled;
                        paperBuckObject["checkValidation"] = item.checkValidation;

                        FinalPaperBuckArray.push(paperBuckObject);
                        //send json stringify variants data in formdata
                    }
                });
                console.log(FinalPaperBuckArray)
                //Check Any Paperback exist or not
                if (FinalPaperBuckArray.length > 0) {
                    formData.append("paper_book", JSON.stringify(FinalPaperBuckArray));
                } else {
                    const defaultvalue = [];
                    formData.append("paper_book", "");
                }

            }

            //other site url
            let FinalOtherSiteArray = [];
            if (otherSiteObject.otherSite != null && otherSiteObject.otherSite != "" && otherSiteObject.otherSite.length > 0) {


                //Set Paperbuck Data in Json
                const finalOtherSite = otherSiteObject.otherSite.filter(item => !item.IsDeleted);

                //remove IsDeleted key
                for (let x = 0; x < finalOtherSite.length; x++) {
                    delete finalOtherSite[x]["IsDeleted"];
                }

                finalOtherSite.forEach(item => {
                    if (item.title != null && item.title != "" &&
                        item.url != null && item.url != "") {

                        const otherSiteObject = {};
                        otherSiteObject["id"] = item.id;
                        otherSiteObject["title"] = item.title;
                        otherSiteObject["url"] = item.url;
                        otherSiteObject["price"] = item.price;
        
                        FinalOtherSiteArray.push(otherSiteObject);
                        //send json stringify variants data in formdata
                    }
                });
                console.log(FinalOtherSiteArray)
                //Check Any Paperback exist or not
                if (FinalOtherSiteArray.length > 0) {
                    formData.append("other_site_url", JSON.stringify(FinalOtherSiteArray));
                } else {
                    const defaultvalue = [];
                    formData.append("other_site_url", "");
                }

            }

            //add images in formdata
            images.map((item) => {
                formData.append("image[]", item);
            });

            //send featured image in formdata
            formData.append("featured_image[]", featured_img[0]);


            //collections
            if (selectedCollection.length > 0) {
                formData.append("product_category", selectedCollection.toString());
            }

            //api call
            axiosInstance.post(ADD_PRODUCT, formData).then((res) => {
                setLoading(false);
                setToastToggle(true);
                setisError(false);
                setToastMessage(res.data.message);
                if (res) {
                    setTimeout(() => {
                        history.push("/admin/importProduct");
                    }, 2000)
                }
            }).catch((error) => {
                setToastToggle(true);
                if (error.response.data.error) {
                    setToastMessage(error.response.data.error);
                } else {
                    setToastMessage("Something went wrong");
                }
                setisError(true);
                setLoading(false);
            })
        }
    };

    return (
        <>
            <Page
                title="Add Product"
            >
                <div className="page-wrapper product-container">
                    <Layout>
                        <Layout.Section>
                            <Card sectioned>
                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Title</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"
                                        placeholder="Product Title"
                                        error={validation.product_title ? "Title can't be blank." : false}
                                        value={addProd.product_title || ""}
                                        onChange={(value) => onHandleChange("product_title", value)}
                                        maxLength={150}
                                        showCharacterCount
                                    />
                                </div>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Author Name</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"
                                        placeholder="Author Name"
                                        error={validation.author_name ? "Author Name can't be blank." : false}
                                        value={addProd.author_name || ""}
                                        onChange={(value) => onHandleChange("author_name", value)}
                                        maxLength={100}
                                        showCharacterCount
                                    />
                                </div>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">About The Book</label>
                                        </div>
                                    </div>

                                    <Editor />

                                    <ReactQuill
                                        theme="snow"
                                        defaultValue={addProd.about_book || ""}
                                        onChange={(value) =>
                                            onHandleChange("about_book", value)
                                        }
                                        modules={modules}
                                        formats={formats}
                                    />
                                </div>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">About The Author</label>
                                        </div>
                                    </div>

                                    <AuthorEditor />
                                    <ReactQuill
                                        theme="snow"
                                        defaultValue={addProd.about_author || ""}
                                        onChange={(value) =>
                                            onHandleChange("about_author", value)
                                        }
                                        modules={authorModules}
                                        formats={authorFormats}
                                    />
                                </div>

                                <div className="text_editor_wrapper">
                                    <div className="Polaris-Labelled__LabelWrapper mt-2">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Original Publication Date</label>
                                        </div>
                                    </div>

                                    <TextField
                                        autoComplete="off"

                                        placeholder="Original Publication Date"
                                        error={validation.publishing_date ? "Original Publication Date can't be blank." : false}
                                        value={addProd.publishing_date || ""}
                                        onChange={(value) => onHandleChange("publishing_date", value)}
                                        maxLength={100}
                                        showCharacterCount
                                    />
                                </div>
                            </Card>

                            <Card title="Media" sectioned>
                                <label class="Polaris-Label__Text">Featured
                                    Image</label>
                                <FeaturedImg getImagesFromFeaturedImage={getImagesFromFeaturedImage} />
                                {validation.featured_img ? (
                                    <div className="Polaris-InlineError">
                                        <div class="Polaris-InlineError__Icon"><span class="Polaris-Icon"><span
                                            class="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20"
                                                class="Polaris-Icon__Svg"
                                                focusable="false"
                                                aria-hidden="true"><path
                                                    d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path></svg></span>
                                        </div>
                                        <span>Featured image is required</span>
                                    </div>) : null
                                }
                                <AddImage getImagesFromAddImage={getImagesFromAddImage} />
                                <br />
                                <TextStyle variation="warning">Note: Product Image size If square then (500*500) and if ractangular then (500*800).</TextStyle>
                            </Card>

                            
                        </Layout.Section>
                        <Layout.Section secondary>
                            <Card title="Product Organization" sectioned>
                                <FormLayout>
                                    <FormLayout.Group>

                                        <Select
                                            label="Type"
                                            placeholder="Select Type"
                                            options={Typeoptions}
                                            onChange={(value) => onHandleChange("product_type", value)}
                                            value={addProd.product_type || ""}
                                            error={validation.product_type ? "Select Product Type" : false}
                                        />
                                        <Selectreact
                                            className="dropdown"
                                            placeholder="Select Category"
                                            value={collectionData.filter(obj => selectedCollection.includes(obj.value))}
                                            options={collectionData}
                                            onChange={handleCollectionChange}
                                            isMulti
                                            isClearable
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 100 })
                                            }}
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>

                        </Layout.Section>

                    </Layout>
                    <br />
                    <Layout>
                        <Layout.Section>
                        <Card title="Paperback" sectioned>
                                {(paperBuckObject.paperBuck.length > 0) && (
                                    paperBuckObject.paperBuck.map((item) => {
                                        if (item.isAdded) {
                                            return (
                                                <Card.Section>
                                                    <div className="option-wrapper">
                                                        <div className="row" style={{ alignItems: "end" }}>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Title"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    value={item.textFieldValue || ""}
                                                                    onChange={(value) =>
                                                                        paperBuckObject.updateTextFieldTitleValue(item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Link"
                                                                    placeholder="Link"
                                                                    type="text"
                                                                    value={item.urlValue || ""}
                                                                    onChange={(value) =>
                                                                        paperBuckObject.updateTextFieldLinkValue("link", item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Price"
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    value={item.paperbackPrice || ""}
                                                                    onChange={(value) =>
                                                                        paperBuckObject.updateTextFieldUrlPriceValue(item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-2  col-md-4 col-sm-12 col-xs-12">
                                                                <Button monochrome type="button" onClick={() => paperBuckObject.removePaperBuckFromDom(item.id)}><Icon source={DeleteMinor} color="black" /></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Section>
                                            )
                                        }
                                    })
                                )}
                                {validation.paperBuck ? (
                                    <div className="Polaris-InlineError" style={{ marginLeft: '19px' }}>
                                        <div class="Polaris-InlineError__Icon" style={{ marginTop: '3px' }}><span class="Polaris-Icon"><span class="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path></svg></span></div>
                                        <span>Please enter Valid Link of Paper Back.</span><br /><br />
                                    </div>) : null
                                }

                                <Card.Section>
                                    <Button onClick={() => paperBuckObject.addPaperBuckToDom()}>
                                        Add another
                                    </Button>
                                </Card.Section>
                            </Card>

                            <Card title="Other Site URL" sectioned>
                                {(otherSiteObject.otherSite.length > 0) && (
                                    otherSiteObject.otherSite.map((item) => {
                                        if (item.isAdded) {
                                            return (
                                                <Card.Section>
                                                    <div className="option-wrapper">
                                                        <div className="row" style={{ alignItems: "end" }}>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Title"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    value={item.title || ""}
                                                                    onChange={(value) =>
                                                                        otherSiteObject.updateTextFieldTitleValue(item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Link"
                                                                    placeholder="Link"
                                                                    type="text"
                                                                    value={item.url || ""}
                                                                    onChange={(value) =>
                                                                        otherSiteObject.updateTextFieldLinkValue("link", item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                                                <TextField
                                                                    autoComplete="off"
                                                                    label="Price"
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    value={item.price || ""}
                                                                    onChange={(value) =>
                                                                        otherSiteObject.updateTextFieldUrlPriceValue(item.id, value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-lg-2  col-md-4 col-sm-12 col-xs-12">
                                                                <Button monochrome type="button" onClick={() => otherSiteObject.removeOtherSiteFromDom(item.id)}><Icon source={DeleteMinor} color="black" /></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Section>
                                            )
                                        }
                                    })
                                )}
                                {validation.otherSite ? (
                                    <div className="Polaris-InlineError" style={{ marginLeft: '19px' }}>
                                        <div class="Polaris-InlineError__Icon" style={{ marginTop: '3px' }}><span class="Polaris-Icon"><span class="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path></svg></span></div>
                                        <span>Please enter Valid Link of Other Site.</span><br /><br />
                                    </div>) : null
                                }

                                <Card.Section>
                                    <Button onClick={() => otherSiteObject.addOtherSiteToDom()}>
                                        Add another
                                    </Button>
                                </Card.Section>
                            </Card>
                            
                            <Card title="Variants">
                                <Card.Section>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                        label="This product has multiple options,like different sizes or colors"
                                    />
                                </Card.Section>

                                {isChecked &&
                                    vary.varients.map((item) => {
                                        if (item.isAdded) {
                                            return (
                                                <SelectVariants
                                                    handleOptionValueChange={(isSubmit) => vary.handleOptionValueChange(item.id, "", item.textFieldValue, isSubmit)}
                                                    deleteOptionValueChange={(value) => vary.deleteOptionValueChange(item.id, value)}
                                                    updateTextFiled={(value) => vary.updateTextFiled(item.id, value)}
                                                    removeVarientFromDom={() => vary.removeVarientFromDom(item.id)}
                                                    variantData={item}
                                                />
                                            )
                                        }

                                    }
                                    )}

                                {isChecked && (vary.comboVariant != []) && (vary.comboVariant.length > 0) && (
                                    <div className="merchant">
                                        <ReactBootStrap.Table id="students">
                                            <thead>
                                                <tr>
                                                    <th>Variant</th>
                                                    <th>Price</th>
                                                    <th>SKU</th>
                                                    <th>Compare at price</th>
                                                    <th>Upload EBook File</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {vary.comboVariant.map((el, i) =>

                                                    <tr key={i} className="variant-img">
                                                        <td>{el.IsDeleted ? <strike style={{ "color": "red" }}>{el.varient_title}</strike> : el.varient_title}</td>
                                                        <td>
                                                            <TextField
                                                                labelHidden
                                                                label="Price"
                                                                autoComplete="off"
                                                                prefix="$"
                                                                placeholder="0.00"
                                                                value={el.price || ""}
                                                                onChange={(value) => onVarientDataChange("price", value, el.varient_title)}
                                                                type="number"
                                                                disabled={el.IsDeleted ? true : false}
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                label="SKU"
                                                                labelHidden
                                                                autoComplete="off"
                                                                placeholder="SKU"
                                                                value={el.sku || ""}
                                                                onChange={(value) => onVarientDataChange("sku", value, el.varient_title)}
                                                                disabled={el.IsDeleted ? true : false}
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                label="Compare at price"
                                                                type="number"
                                                                labelHidden
                                                                autoComplete="off"
                                                                placeholder="0.00"
                                                                value={el.compared_price || ""}
                                                                onChange={(value) => onVarientDataChange("compared_price", value, el.varient_title)}
                                                                disabled={el.IsDeleted ? true : false}
                                                            />
                                                        </td>
                                                        <td>
                                                            {
                                                                <div className="variant-img-select">
                                                                    <Button>
                                                                        <UploadFile getFilesFromFiles={getFilesFromFiles} />
                                                                    </Button></div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </ReactBootStrap.Table>
                                    </div>
                                )}
                                {validation.variant ? (
                                    <div className="Polaris-InlineError" style={{ marginLeft: '19px' }}>
                                        <div class="Polaris-InlineError__Icon" style={{ marginTop: '3px' }}><span class="Polaris-Icon"><span class="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path></svg></span></div>
                                        <span>Please enter variant price, sku and Upload EBook File.</span><br /><br />
                                    </div>) : null
                                }
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <Stack>
                                <div>
                                    <Button>Cancel</Button>
                                </div>
                                <div>
                                    <Button
                                        loading={loading}
                                        primary onClick={() => onProductSubmit()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Stack>
                        </Layout.Section>
                    </Layout>
                </div>
                {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
            </Page>
        </>
    );
}
