import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  DataTable,
  Icon,
  TextField,
  Page,
  Modal,
  Badge,
  Pagination,
  Stack,
  Select,
  Spinner,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { SearchMinor } from "@shopify/polaris-icons";
import { ProductStatusOptions, defaultPageOptions, ASSET_PATH, showing, SEARCH_IMG } from "../../appconfig/Settings";
import SkeletonImportProduct from "./skeletons/SkeletonImportProduct";
import axiosInstance from "../../services/axios.service";
import { IMPORT_PRODUCT, APPROVE_PRODUCT, DELETE_PRODUCT } from "../../apis/ApiConstant";
import "../scss/myproducts.scss";
import { setPagination } from "../../appconfig/AppHelper";
import ToasterToggle from "../components/Toster";

export default function ProductList() {
  document.title = "Import List";
  const [paginationActive, setPaginationActive] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [perPage, setPerPage] = useState(showing);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [selProductId, setSelProductId] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [radioValue, setRadioValue] = useState();
  const [onApproveError, setOnApproveError] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const NO_IMAGE = ASSET_PATH + "no-image.jpg?v=1654230497";

  useEffect(() => {
    getDataFromAPI();
  }, [])

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, status]);

  useEffect(() => {
    setOnApproveError(false);
  }, [radioValue]);

  //approve modal
  const approveModalAddActive = (product_id) => {
    setSelProductId(product_id);
    setOnApproveError(false);
    setApproveModal(true);
  };

  //delete modal
  const deleteModalAddActive = (product_id) => {
    setSelProductId(product_id);
    setDeleteModal(true);
  };

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleSearchButton = () => {
    setLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setLoading(false);
  };

  const handleStatusChange = (value) => {
    if (value == "All") {
      paginationInner.page = 1;
      setSearch("");
      setStatus("");
    } else {
      paginationInner.page = 1;
      setStatus(value);
    }
  }

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  }

  const getDataFromAPI = () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    axiosInstance.get(IMPORT_PRODUCT + "?per_page=" + perPage + "&page=" + page + "&search=" + search + "&status=" + status).then((res) => {
      const productList = res.data.data;
      if (productList.length == 0) {
        setTableRows([]);
        setPaginationActive(false);
      } else {
        let tableData = [];
        for (let i = 0; i < productList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(<img src={productList[i].featured ? productList[i].featured.image : NO_IMAGE} alt="Featured Image"></img>);
          tableRowsAppend.push(productList[i].product_title);
          tableRowsAppend.push(productList[i].author_name);
          tableRowsAppend.push(productList[i].publishing_date);
          tableRowsAppend.push(productList[i].product_type);
          //check status
          if (productList[i].status == 1) {
            tableRowsAppend.push(<Badge status="success">Approved</Badge>);
          } else {
            tableRowsAppend.push(<Badge status="attention">Pending</Badge>);
          }
          tableRowsAppend.push(<><Button onClick={() => handleViewProduct(productList[i].id)}>View</Button> <Button primary onClick={() => approveModalAddActive(productList[i].id)}>Approve</Button> <Button destructive onClick={() => deleteModalAddActive(productList[i].id)}>Delete</Button></>);
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);
      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = res.data;
      const pagination = setPagination(current_page, per_page, last_page, total);
      setPageinationInner(pagination);
    });
  }

  const approveProduct = () => {
    setButtonLoading(true);
    let requestObj = {};
    requestObj["product_id"] = selProductId;
    axiosInstance.post(APPROVE_PRODUCT, requestObj).then((res) => {
      setButtonLoading(false);
      setApproveModal(false);
      setToastToggle(true);
      getDataFromAPI();
      setToastMessage(res.data.success);
      setTimeout(() => {
        history.push("/admin/products");
      }, 2000)
      // getDataFromAPI();
    }).catch((error) => {
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  }

  const deleteProduct = () => {
    setButtonLoading(true);
    axiosInstance.post(DELETE_PRODUCT + '/' + selProductId).then((res) => {
      setButtonLoading(false);
      setDeleteModal(false);
      setToastToggle(true);
      getDataFromAPI();
      setToastMessage(res.data.message);
      getDataFromAPI();
    }).catch((error) => {
      setToastToggle(true);
      setToastMessage(error.response.data.error);
      setisError(true);
    })
  }

  const history = useHistory();
  const handleViewProduct = (product_id) => {
    let path = `/admin/view/importproduct`;
    history.push({ pathname: path, state: product_id });
  };

  const handleAddProduct = () => {
    let path = `/admin/addProduct`;
    history.push(path);
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    )
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row myproducts-list justify-content-center">
          <Page title="Import List"
            primaryAction={{
              content: "Add Product",
              onAction: handleAddProduct,
            }}
          >
            <Card>
              <Card.Section subdued>
                <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                  <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                    <TextField
                      labelHidden
                      autoComplete="off"
                      placeholder="Enter Product Title"
                      value={search || ""}
                      onChange={(value) => handleSearch(value)}
                      prefix={<Icon source={SearchMinor} color="inkLighter" />}
                      clearButton
                      onClearButtonClick={() => handleClearSearch()}
                    />
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 form-group">
                    <Button
                      loading={loading}
                      onClick={() => handleSearchButton()}
                      primary
                      fullWidth
                    >
                      SEARCH
                    </Button>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Select
                      label="Status"
                      labelInline
                      onChange={handleStatusChange}
                      value={status}
                      options={ProductStatusOptions}
                    ></Select>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <Select
                      label="Show"
                      labelInline
                      onChange={handleChangePerpage}
                      value={perPage}
                      options={defaultPageOptions}
                    ></Select>
                  </div>
                </div>
              </Card.Section>

              <div className="data-wrapper">
                {pageLoad ? (
                  <>
                    <div>
                      <DataTable
                        columnContentTypes={[
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                        ]}
                        headings={
                          [
                            "",
                            "Product Title",
                            "Author Name",
                            "Publication Date",
                            "Type",
                            "Status",
                            "Actions",
                          ]}
                        rows={tableRows}
                        footerContent={
                          tableRows.length > 0 ? (
                            <span>{paginationInner.showing}</span>
                          ) : null
                        }
                      />
                      <div className="paginate">
                        <Stack distribution="center">
                          {paginationActive ? (
                            <Paginate />
                          ) : null}
                        </Stack>
                      </div>
                      {tableRows.length > 0 ? null : (
                        <div className="Polaris-ResourceList__EmptySearchResultWrapper">
                          <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
                            <Stack.Item>
                              <img
                                src={SEARCH_IMG}
                                alt="search_img"
                                className="Polaris-EmptySearchResult__Image"
                              />
                            </Stack.Item>
                            <Stack.Item>
                              <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                                No Products found
                              </p>
                            </Stack.Item>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Approve Modal */}
                    <Modal
                      open={approveModal}
                      onClose={() => setApproveModal(false)}
                      title="Do you want to approve this product?"
                      primaryAction={[
                        {
                          content: 'Confirm',
                          onAction: () => approveProduct(),
                          loading: buttonLoading,
                        },
                      ]}
                      secondaryActions={{
                        content: 'Cancel',
                        onAction: () => setApproveModal(false),
                      }}
                    >
                    </Modal>

                    {/* Delete Modal */}
                    <Modal
                      open={deleteModal}
                      onClose={() => setDeleteModal(false)}
                      title="Do you really want to delete this product?"
                      primaryAction={[
                        {
                          content: 'Confirm',
                          onAction: () => deleteProduct(),
                          loading: buttonLoading
                        },
                      ]}
                      secondaryActions={{
                        content: 'Cancel',
                        onAction: () => setDeleteModal(false),
                      }}
                    >
                    </Modal>

                  </>
                ) : (
                  <SkeletonImportProduct />
                )}
                {dataTableInnerLoading ? (
                  <div className="loading">
                    <Spinner size="large" />
                  </div>
                ) : null}
              </div>
            </Card>
            {toasttoggle && <ToasterToggle toastmessage={toastMessage} toastactive={() => setToastToggle(false)} isErrorMessage={isError} />}
          </Page>
        </div>
      </div>
    </>
  );
}
