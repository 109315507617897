import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  DataTable,
  Icon,
  TextField,
  Spinner,
  Page,
  Pagination,
  Stack,
  Select,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { SearchMinor } from "@shopify/polaris-icons";
import { defaultPageOptions, showing, SEARCH_IMG } from "../../appconfig/Settings";
import SkeletonCustomerList from "./skeletons/SkeletonCustomerList";
import "../scss/myproducts.scss";
import axiosInstance from "../../services/axios.service";
import { LIST_CUSTOMER } from "../../apis/ApiConstant";
import { setPagination } from "../../appconfig/AppHelper";
import CustomDatePicker from "../components/CustomDatePicker";
import moment from "moment";
import { formatDateTime } from "../../appconfig/AppHelper";

export default function CustomerList() {
  document.title = "Customers";
  const [pageLoad, setPageLoad] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);

  useEffect(() => {
    getDataFromAPI();
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, startDate, endDate]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleSearchButton = () => {
    setLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setLoading(false);
  };

  const history = useHistory();

  //Handle Date Change
  const handleDateChangeEvent = (start, end) => {
    paginationInner.page = 1;
    //Update State Object
    setStartDate(start);
    setEndDate(end);
  }

  const getDataFromAPI = () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    const startStamp = startDate + " 00:00:00";
    const endStamp = endDate + " 23:59:59";
    axiosInstance.get(LIST_CUSTOMER + "?per_page=" + perPage + "&page=" + page + "&search=" + search + "&start_date=" + startStamp + "&end_date=" + endStamp).then((res) => {
      const customerList = res.data.data;

      if (customerList.length == 0) {
        setTableRows([]);
        setPaginationActive(false);
      } else {
        let tableData = [];
        for (let i = 0; i < customerList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(i + 1);
          tableRowsAppend.push(customerList[i].firstname + " " + customerList[i].lastname);
          tableRowsAppend.push(customerList[i].email);
          tableRowsAppend.push(customerList[i].mobile);
          tableRowsAppend.push(customerList[i].shopify_customer_id);
          tableRowsAppend.push(customerList[i].order_count);
          tableRowsAppend.push("$"+customerList[i].total_spent);
          tableRowsAppend.push(formatDateTime(customerList[i].created_at));
          //push in empty array
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);
      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = res.data;
      const pagination = setPagination(current_page, per_page, last_page, total);
      setPageinationInner(pagination);
    });
  }

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    )
  }

  return (
    <>
      <div className="main-content container custom-container">
        <div className="row myproducts-list justify-content-center">

          <Page title="Customers">
            <CustomDatePicker startDate={startDate} endDate={endDate} onChange={(startDate, endDate) => handleDateChangeEvent(startDate, endDate)}></CustomDatePicker>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>

              <Card>
                <Card.Section subdued>
                  <div className="row" style={{ padding: "0px 23px 0px 0" }}>
                    <div className="col-lg-8 col-md-4 col-sm-12 col-xs-12">
                      <TextField
                        labelHidden
                        autoComplete="off"
                        placeholder="Enter customer name or email"
                        value={search || ""}
                        onChange={(value) => handleSearch(value)}
                        prefix={<Icon source={SearchMinor} color="inkLighter" />}
                        clearButton
                        onClearButtonClick={() => handleClearSearch()}
                      />
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                      <Button
                        loading={loading}
                        onClick={() => handleSearchButton()}
                        primary
                        fullWidth
                      >
                        SEARCH
                      </Button>
                    </div>

                    <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                      <Select
                        label="Show"
                        labelInline
                        onChange={handleChangePerpage}
                        value={perPage}
                        options={defaultPageOptions}
                      ></Select>
                    </div>
                  </div>
                </Card.Section>
                <div className="data-wrapper">
                  {pageLoad ? (
                    <div>
                      <DataTable
                        columnContentTypes={[
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                          "text",
                        ]}
                        headings={[
                          "Sr No.",
                          "Full Name",
                          "Email",
                          "Mobile No.",
                          "Shopify Customer ID",
                          "Order Count",
                          "Total Spent",
                          "Created At",
                        ]}
                        rows={tableRows}
                        footerContent={
                          tableRows.length > 0 ? (
                            <span>{paginationInner.showing}</span>
                          ) : null
                        }
                      />
                      <div className="paginate">
                        <Stack distribution="center">
                          {paginationActive ? (
                            <Paginate />
                          ) : null}
                        </Stack>
                      </div>
                      {tableRows.length == 0 ? (

                        <div className="Polaris-ResourceList__EmptySearchResultWrapper">
                          <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
                            <Stack.Item>
                              <img
                                src={SEARCH_IMG}
                                alt="search_img"
                                className="Polaris-EmptySearchResult__Image"
                              />
                            </Stack.Item>
                            <Stack.Item>
                              <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                                No Customer found
                              </p>
                            </Stack.Item>
                          </div>
                        </div>

                      ) : null}
                    </div>

                  ) : (
                    <SkeletonCustomerList />
                  )}
                  {dataTableInnerLoading ? (
                    <div className="loading">
                      <Spinner size="large" />
                    </div>
                  ) : null}
                </div>
              </Card>

            </div>
          </Page>
        </div>
      </div>
    </>
  );
}
